import { ACTIVITY_TYPE } from '@/common/utils'

export function formatGoodsData(list) {
  if (!list) return []
  // 组装活动数据
  let goodLists = []
  let beiExchangeList = []
  let exchangeMapList = {}
  const activityKeys = []
  const goods = []
  // 参与活动商品
  goodLists = list.filter(
    item => item?.selectedActivityList?.length > 0 && item?.selectedActivityList?.some(
      sItem => ((sItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || sItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
      sItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || sItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && item.expandMap?.present != true) ||
      sItem.activityType == ACTIVITY_TYPE.FIXEDCOMBINATION || sItem.activityType == ACTIVITY_TYPE.CUSTOMCOMBINATION ||
      sItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || sItem.activityType == ACTIVITY_TYPE.LADDERMINUS || sItem.activityType == ACTIVITY_TYPE.DISCOUNT ||
      sItem.activityType==ACTIVITY_TYPE.SING_CYCLE_MINUS||sItem.activityType==ACTIVITY_TYPE.SING_LADDER_MINUS||
      sItem.activityType == ACTIVITY_TYPE.SPECIAL
    )
  )
  // 满换被换购商品、满赠赠品
  beiExchangeList = list.filter(item => (item.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || item.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
    item.activityType == ACTIVITY_TYPE.GIFT_AUTO || item.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && item.expandMap?.present == true)
  // console.log('beiExchangeList',beiExchangeList)
  const activityIdList = [...new Set(goodLists.map((item) => item?.selectedActivityList[0].activityId))] || []
  // console.log('activityIdList',activityIdList)
  activityIdList.forEach((item) => {
    const exchangeObjVal = goodLists.filter(eItem => eItem.selectedActivityList[0].activityId == item)
    exchangeObjVal[0].isShowDesc = true
    const beiExchangeObjVal = beiExchangeList.filter(eItem => eItem.activityId == item)
    if (exchangeObjVal[0].selectedActivityList[0].activityType == ACTIVITY_TYPE.AUTOEXCHANGE || exchangeObjVal[0].selectedActivityList[0].activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
      exchangeObjVal[0].selectedActivityList[0].activityType == ACTIVITY_TYPE.GIFT_AUTO || exchangeObjVal[0].selectedActivityList[0].activityType == ACTIVITY_TYPE.GIFT_MANUAL) {
      exchangeMapList = {
        ...exchangeMapList,
        [`${item}`]: [...exchangeObjVal, ...beiExchangeObjVal]
      }
    } else {
      exchangeMapList = {
        ...exchangeMapList,
        [`${item}`]: [...exchangeObjVal]
      }
    }
    exchangeMapList[`${item}`][exchangeMapList[`${item}`].length - 1].isLastItem = true
  })
  // console.log('exchangeMapList',exchangeMapList);
  for (const item of list) {
    if (item?.selectedActivityList?.length > 0) {
      if (item?.selectedActivityList?.some(sItem => ((sItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || sItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
        sItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || sItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && sItem.expandMap?.present != true) ||
        sItem.activityType == ACTIVITY_TYPE.FIXEDCOMBINATION || sItem.activityType == ACTIVITY_TYPE.CUSTOMCOMBINATION ||
        sItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || sItem.activityType == ACTIVITY_TYPE.LADDERMINUS || sItem.activityType == ACTIVITY_TYPE.DISCOUNT ||
        sItem.activityType==ACTIVITY_TYPE.SING_CYCLE_MINUS||sItem.activityType==ACTIVITY_TYPE.SING_LADDER_MINUS||
        sItem.activityType == ACTIVITY_TYPE.SPECIAL)) {
        if (!activityKeys.includes(item.selectedActivityList[0].activityId)) {
          activityKeys.push(item.selectedActivityList[0].activityId)
          goods.push(
            ...exchangeMapList[item.selectedActivityList[0].activityId]
          )
        }
      } else {
        goods.push(item)
      }
    } else {
      if (item.expandMap?.present != true) {
        goods.push(item)
      }
    }
  }
  return goods
}

export function formatGoodsData2(list) {
  if (!list) return []

  // 是否必须合并售后排序,
  const _waitMergeIds = {}
  list.forEach(item => {
    item._checked = true
    const valid = item.selectedActivityList?.some(it => it.mustMergeReturn)
    if (valid) {
      const id = item.selectedActivityList[0].activityId
      _waitMergeIds[id] = _waitMergeIds[id]
        ? [..._waitMergeIds[id], { ...item, mustMergeReturn: true, _class: 'merge' }]
        : [{ ...item, mustMergeReturn: true, _class: 'start-merge', _selectedMerge: true }]
    }
  })
  for (const id in _waitMergeIds) {
    const len = _waitMergeIds[id].length - 1
    _waitMergeIds[id][len] = { ..._waitMergeIds[id][len], _class: 'end-merge' }
    _waitMergeIds[id][0] = { ..._waitMergeIds[id][0], _totalMergeLen: _waitMergeIds[id].length }
  }
  const waitMergeList = []
  for (const id in _waitMergeIds) {
    waitMergeList.push(..._waitMergeIds[id])
  }
  list = [
    ...waitMergeList,
    ...list.filter(item => {
      return !item.selectedActivityList || !item.selectedActivityList?.some(it => it.mustMergeReturn)
    })
  ]

   // 组装活动数据
   let goodLists = []
   let beiExchangeList = []
   let exchangeMapList = {}
   const activityKeys = []
   const goods = []
   // 参与活动商品
   goodLists = list.filter(
     item => item?.selectedActivityList?.length > 0 && item?.selectedActivityList?.some(
       sItem => ((sItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || sItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
       sItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || sItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && item.expandMap?.present != true) ||
       sItem.activityType == ACTIVITY_TYPE.FIXEDCOMBINATION || sItem.activityType == ACTIVITY_TYPE.CUSTOMCOMBINATION ||
       sItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || sItem.activityType == ACTIVITY_TYPE.LADDERMINUS || sItem.activityType == ACTIVITY_TYPE.DISCOUNT ||
       sItem.activityType==ACTIVITY_TYPE.SING_CYCLE_MINUS||sItem.activityType==ACTIVITY_TYPE.SING_LADDER_MINUS||
       sItem.activityType == ACTIVITY_TYPE.SPECIAL
     )
   )
   // 满换被换购商品、满赠赠品
   beiExchangeList = list.filter(item => (item.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || item.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
     item.activityType == ACTIVITY_TYPE.GIFT_AUTO || item.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && item.expandMap?.present == true)
   const activityIdList = [...new Set(goodLists.map((item) => item?.selectedActivityList[0].activityId))] || []
   activityIdList.forEach((item) => {
     const exchangeObjVal = goodLists.filter(eItem => eItem.selectedActivityList[0].activityId == item)
     exchangeObjVal[0].isShowDesc = true
     const beiExchangeObjVal = beiExchangeList.filter(eItem => eItem.activityId == item)
     if (exchangeObjVal[0].selectedActivityList[0].activityType == ACTIVITY_TYPE.AUTOEXCHANGE || exchangeObjVal[0].selectedActivityList[0].activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
       exchangeObjVal[0].selectedActivityList[0].activityType == ACTIVITY_TYPE.GIFT_AUTO || exchangeObjVal[0].selectedActivityList[0].activityType == ACTIVITY_TYPE.GIFT_MANUAL) {
       exchangeMapList = {
         ...exchangeMapList,
         [`${item}`]: [...exchangeObjVal, ...beiExchangeObjVal]
       }
     } else {
       exchangeMapList = {
         ...exchangeMapList,
         [`${item}`]: [...exchangeObjVal]
       }
     }
     exchangeMapList[`${item}`][exchangeMapList[`${item}`].length - 1].isLastItem = true
   })
   for (const item of list) {
     if (item?.selectedActivityList?.length > 0) {
       if (item?.selectedActivityList?.some(sItem => ((sItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || sItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && sItem.expandMap?.present != true) ||
         sItem.activityType == ACTIVITY_TYPE.FIXEDCOMBINATION || sItem.activityType == ACTIVITY_TYPE.CUSTOMCOMBINATION ||
         sItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || sItem.activityType == ACTIVITY_TYPE.LADDERMINUS || sItem.activityType == ACTIVITY_TYPE.DISCOUNT ||
         sItem.activityType==ACTIVITY_TYPE.SING_CYCLE_MINUS||sItem.activityType==ACTIVITY_TYPE.SING_LADDER_MINUS||
         sItem.activityType == ACTIVITY_TYPE.SPECIAL)) {
         if (!activityKeys.includes(item.selectedActivityList[0].activityId)) {
           activityKeys.push(item.selectedActivityList[0].activityId)
           if(exchangeMapList[item.selectedActivityList[0].activityId]){
              goods.push(
                ...exchangeMapList[item.selectedActivityList[0].activityId]
              )
           }
         }
       } else {
         goods.push(item)
       }
     } else {
       if (item.expandMap?.present != true) {
         goods.push(item)
       }
     }
   }
   return goods
}

export function formatOrder(order) {
  let data = order.productItems || []
  // console.log('productItems',order.productItems)
  const keyMap = {
    prodMainPic: 'productLogo',
    brand: 'brand',
    productChineseName: 'productName',
    productSpec: 'productSpec',
    manufactureCompany: 'manufacture',
    // activityType: 'activityType',
    orderOrgItemMarkets: 'selectedActivityList',
    productUnit: 'productUnit',
    payPrice: 'actualPrice',
    originalPrice: 'price',
    qty: 'num',
    tradeFlag: 'expandMap',
    enum: 'num',// 如果存在包裹个数，取包裹里的数量
  }

  // activityType 0 普通 2润划算
  // orderOrgItemMarkets。activityType 满减活动 11循环满减 12阶梯满减 13满折活动
  // NONE：其他商品，BETRADE：满换，TRADE：换购

  data = data.map((item) => {
    for (const key in keyMap) {
      if (key === 'orderOrgItemMarkets') {
        item[key] = item[key]
          ? item[key].map((it) => {
            it.reachPrice = it.isReached
            return it
          })
          : []
      }
      if (keyMap[key] == 'expandMap') {
        const value = (item[key] == 'TRADE' || item[key] == 'GIVEAWAY')
        if (value) {
          item.activityId = item.orderOrgItemMarkets[0].activityId
          item.activityType = item.orderOrgItemMarkets[0].activityType
        }
        item[keyMap[key]] = { present: value }
      } else {
        if( item[key] )
          item[keyMap[key]] = item[key]
      }
    }
    return item
  })
}