<template>
  <div>
    <Modal v-model="showModal" title="提示" width="480" class-name="jc-modal" @on-cancel="showModal = false">
      <span>{{ jcState == 'un_auth' ? '商家开启了建采认证，完成建采后才能采购商品。您已提交建采申请，请耐心等待商家审核或联系商家处理。' : '商家开启了建采认证，完成建采后才能采购商品'
      }}</span>
      <template #footer>
        <div class="fail-modal-footer">
          <Button v-if="jcState != 'un_auth'" type="default" @click="showModal = false">取消</Button>
          <Button type="primary" @click="onOk">{{ jcState != 'un_auth' ? '去建采' : '我知道了' }}</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="isShowUserAgreementModal" title="用户协议签署提醒" class-name="jc-modal" @on-cancel="cancelUserAgreementModal">
      <span v-if="haveSignedUserAgreement == 0">
        根据润曜健康科技平台集团规定，未签署用户协议将会影响正常采购，请登录用章人账号（{{ signedUserPhone }}）进行签署。
      </span>
      <span v-else-if="haveSignedUserAgreement == 2">
        根据润曜健康科技平台集团规定，未签署用户协议将会影响正常采购，是否签署？
      </span>
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="cancelUserAgreementModal">{{ haveSignedUserAgreement == 0 ? '我知道了' : '我再想想' }}</Button>
          <Button v-if="haveSignedUserAgreement == 2" type="primary" @click="onSign">立即签署</Button>
        </div>
      </template>
    </Modal>
    <Modal
      v-model="isShowPurchaseModal"
      title="发起建采"
      class-name="jc-modal"
      cancel-text="暂不"
      @on-ok="agreeConfirm"
      @on-cancel="onCancel"
    >
      <span style="font-weight: 500;font-size: 16px;line-height: 22px;color: rgba(0, 0, 0, 0.9);">确认向{{ shopData.orgAbbr
        ||
        '' }}发起建采申请吗？</span>
      <p style="font-size: 14px;line-height: 22px;color: rgba(0, 0, 0, 0.6);margin-top: 12px;">
        *确认后，系统会将您的企业信息、资质证照材料公开给商家
      </p>
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="onCancel">暂不</Button>
          <Button type="primary" @click="agreeConfirm">确定</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="isInstructions" title="开户说明" class-name="jc-modal" @on-ok="agreeConfirm" @on-cancel="onCancel">
      <p v-if="openAccountType != '未设置'" class="acc_type">{{ openAccountType }}</p>
      <h3 v-html="openAccountDesc" />
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="onCancel">暂不</Button>
          <Button type="primary" @click="agreeConfirm">确定</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { getCustRelByCustIdOrgId } from '@/common/service/product.js'
import {
  buildCustRelByCustIdOrgId
} from '@/common/service/productDetail.js'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'CheckOrgJcStatus',
  data() {
    return {
      jcState: '', // 建采状态`
      showModal: false, // 是否打开弹框
      orgId: null,
      isShowPurchaseModal: false,
      isInstructions: false,
      openAccountType: '',
      openAccountDesc: '',
      noRelCanBuyState: '', // 商家是否允许为建采客户下单
      isShowUserAgreementModal: false, // 是否打开用户协议弹框
      haveSignedUserAgreement: 1, // 已签署用户协议&质保协议， 0.签章人与登录账号不一致，未签署；1.已签署; 默认 1, 2.签章人与登录账号一致，未签署
      signedUserPhone: '' // 签署人登录账号
    }
  },
  computed: {
    ...mapGetters({
      shopData: 'shopHome/getShopInfo'
    })
  },
  watch: {
    shopData() {
      if (this.shopData?.isHaveOpenAccount) {
        this.getDocAndType()
      }
    }
  },
  methods: {
    // 检查商家建采状态、商家是否允许为建采客户下单
    async checkBuyStatus(orgId) {
      this.orgId = orgId
      const obj = {
        orgId: this.orgId
      }
      const { data } = await getCustRelByCustIdOrgId(obj)
      // 判断是否建采成功
      this.jcState = data?.state
      this.noRelCanBuyState = data?.noRelCanBuyState
      this.signedUserPhone = data?.signedUserPhone
      this.haveSignedUserAgreement = data?.haveSignedUserAgreement
      // 判断商家是否已建采，已建采的不需要做逻辑处理
      // 此处逻辑调整为：先判断是否签署用户协议，已签署则发起建采，未签署则打开弹框提示
      if (data?.haveSignedUserAgreement === 1) {
        if (data?.state === 'success_auth' || data?.noRelCanBuyState == 'open') {
          this.$emit('ok')
          this.showModal = false
        } else {
          this.$emit('noPass')
          this.showModal = true
        }
      } else {
        this.isShowUserAgreementModal = true
      }
    },
    async onOk() {
      if (this.jcState != 'un_auth') {
        // 发起建采
        // const param = {
        //   orgId: this.orgId,
        // }
        // const res = await buildCustRelByCustIdOrgId(param)
        // if (res.code == 200) {
        //   this.$Message.info('建采成功');
        //   this.showModal = false
        // }
        this.purchaseModal()
      } else {
        this.showModal = false
      }
    },
    async getDocAndType() { // 获取开户说明
      const { data } = await getOrgOpenAccount(this.orgId)
      this.openAccountDesc = data.openAccountDesc
      this.openAccountType = data.openAccountType
    },
    purchaseModal() {
      if (this.shopData?.isHaveOpenAccount) {
        this.isInstructions = true
      } else {
        this.isShowPurchaseModal = true
        // 添加埋点
        this.$zhuge.track('店铺-发起建采申请', {
          '店铺名称': this.shopData.orgAbbr
        })
      }
    },
    // 商家和客户建立建采关系
    agreeConfirm: debounce(async function() {
      const param = {
        orgId: this.orgId
      }
      const res = await buildCustRelByCustIdOrgId(param)
      if (res.code == 200) {
        // 添加埋点
        this.$zhuge.track('店铺-成功建采', {
          '店铺名称': this.shopData.orgAbbr
        })
      }
      this.onCancel()
    }),
    onCancel() {
      this.isInstructions = false
      this.showModal = false
      this.isShowPurchaseModal = false
    },
    onSign() {
      this.$router.push('/msg/index?type=4')
    },
    cancelUserAgreementModal() {
      this.isShowUserAgreementModal = false
      // 产品觉得到提交订单再强制拦截会影响体验，所以建采、加购的时候就强制拦截
      // if (this.jcState === 'success_auth' || this.noRelCanBuyState === 'open') {
      //   this.$emit('ok')
      //   this.showModal = false
      // } else {
      //   this.$emit('noPass')
      //   this.showModal = true
      // }
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .jc-modal .ivu-modal-header {
  border: none !important;
}

/deep/ .jc-modal .ivu-modal-footer {
  border: none !important;
}
</style>
