<template>
  <div>
    <div class="header-all">
      <Checkbox v-model="checkAll" @on-change="handleCheckAll">全选</Checkbox>
      <div class="handle-all-other">
        <div class="common good-info">商品信息</div>
        <div class="header-all-content">
          <div class="common" style="width: 100px;text-align: center">中/件包装</div>
          <div class="common" style="width: 200px;text-align: right">单价</div>
          <div class="common" style="width: 112px;text-align: center">数量</div>
          <div class="common" style="width: 60px;text-align: right">金额(元)</div>
          <div class="common" style="width: 80px;text-align: right">操作</div>
        </div>
      </div>
    </div>
    <div v-for="(item,index) in validCartList" v-if="item.storeId" :key="item.storeId" class="campany">
      <div class="header">
        <div class="left-info">
          <div class="w-flex">
            <Checkbox v-model="item.checked" :model-value="item.checkAll" @on-change="campanyCheckAll(item,index)" />
            <Tooltip v-if="item.storeName" max-width="280px">
              <template #content>
                {{ item.storeName }}
              </template>
              <div class="store-name" @click="jumpShopHome(item)">{{ item.storeName }}</div>
            </Tooltip>
          </div>
          <div class="header-coupon-box">
            <div v-for="(cItem, cIndex) in item.mergeCoupons" :key="cIndex" class="header-coupon-item cursor-pointer" :class="{'display-none' : cIndex > 2}" @click="onToCoupon(cItem)">
              {{ cItem.couponMsg }}
              <Icon type="ios-arrow-forward" />
            </div>
          </div>
          <div v-if="item.mergeCoupons && item.mergeCoupons.length" class="header-coupon-more cursor-pointer">
            <Poptip placement="bottom-end" @on-popper-show="onMore(item)">
              <span>更多</span>
              <Icon type="ios-arrow-forward" />
              <template #content>
                <div class="cart-coupon-title w-flex w-flex-align-center">
                  <img :src="require('@/assets/images/car/msg-tip.png')" alt="">
                  券下方展示的商品为购物车内可用此券的商品
                </div>
                <Tabs v-model="activedTab" style="padding-top: 10px">
                  <TabPane v-if="mergeCouponsCurrency && mergeCouponsCurrency.length > 0" label="通用券" name="0" />
                  <TabPane v-if="mergeCouponsGoods && mergeCouponsGoods.length > 0" label="商品券" name="1" />
                </Tabs>
                <div v-show="activedTab === '0'" class="poptip-coupon-content">
                  <div v-for="(cItem, cIndex) in mergeCouponsCurrency" :key="cIndex" class="cart-coupon-item">
                    <div class="cart-coupon-top w-flex w-flex-justify-between">
                      <div class="coupon-item-left w-flex">
                        <div class="coupon-item-type w-flex w-flex-justify-center w-flex-align-center">
                          <span v-if="cItem.couponType == 'coupon_full_decrement'">满减券</span>
                          <span v-else-if="cItem.couponType == 'coupon_full_discount'">满折券</span>
                          <span v-else-if="cItem.couponType == 'coupon_full_rebate'">满返券</span>
                          <span v-else-if="cItem.couponType == 'coupon_new_customer'">新客券</span>
                          <span v-else-if="cItem.couponType == 'coupon_commodity'">商品券</span>
                        </div>
                        <div class="coupon-item-content" :class="{active: cItem.couponMsg.length < 20}">
                          <div class="coupon-item-label">{{ cItem.couponMsg }}</div>
                          <div class="coupon-item-money" :class="{'red': !cItem.reachThresholdFlag}">{{ cItem.reachThresholdFlag ? `小计${returnFloat(cItem.diffOrTotalAmount || 0, '0.01')}元` : `还差${returnFloat(cItem.diffOrTotalAmount || 0, '0.01')}元` }}</div>
                        </div>
                      </div>
                      <div class="coupon-item-right w-flex w-flex-justify-center w-flex-align-center cursor-pointer" @click="onToCoupon(cItem)">
                        {{ cItem.reachThresholdFlag ? "再逛逛" : "去凑单" }}
                      </div>
                    </div>
                    <div class="cart-coupon-swiper">
                      <swiper
                        :ref="'mySwiper' + cItem.couponCustomerNo"
                        :options="swiperOption"
                      >
                        <swiper-slide v-for="(pItem, pIndex) in cItem.productList" :key="pItem.productId + pIndex">
                          <div class="swiper-slide-cart w-flex w-flex-justify-center w-flex-align-center">
                            <img class="swiper-slide-img" :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="" mode="aspectFit">
                            <div v-if="pItem.selected" class="swiper-slide-select">
                              <img :src="require('@/assets/images/car/selected.png')" alt="">
                            </div>
                            <div class="swiper-slide-num w-flex w-flex-justify-center w-flex-align-center">
                              <span>x</span>{{ pItem.num }}
                            </div>
                          </div>
                        </swiper-slide>
                        <div v-if="cItem?.productList?.length> 6" slot="button-prev" class="banner-button-prev">
                          <Icon type="ios-arrow-down" :size="12" :color="'#fff'" />
                        </div>
                        <div v-if="cItem?.productList?.length> 6" slot="button-next" class="banner-button-next">
                          <Icon type="ios-arrow-up" :size="12" :color="'#fff'" />
                        </div>
                      </swiper>
                    </div>
                  </div>
                </div>
                <div v-show="activedTab === '1'" class="poptip-coupon-content">
                  <div v-for="(cItem, cIndex) in mergeCouponsGoods" :key="cIndex" class="cart-coupon-item">
                    <div class="cart-coupon-top w-flex w-flex-justify-between">
                      <div class="coupon-item-left w-flex">
                        <div class="coupon-item-type w-flex w-flex-justify-center w-flex-align-center">
                          <span v-if="cItem.couponType == 'coupon_full_decrement'">满减券</span>
                          <span v-else-if="cItem.couponType == 'coupon_full_discount'">满折券</span>
                          <span v-else-if="cItem.couponType == 'coupon_full_rebate'">满返券</span>
                          <span v-else-if="cItem.couponType == 'coupon_new_customer'">新客券</span>
                          <span v-else-if="cItem.couponType == 'coupon_commodity'">商品券</span>
                        </div>
                        <div class="coupon-item-content" :class="{active: cItem.couponMsg.length < 20}">
                          <div class="coupon-item-label">{{ cItem.couponMsg }}</div>
                          <div class="coupon-item-money" :class="{'red': !cItem.reachThresholdFlag}">{{ cItem.reachThresholdFlag ? `小计${returnFloat(cItem.diffOrTotalAmount || 0, '0.01')}元` : `还差${returnFloat(cItem.diffOrTotalAmount || 0, '0.01')}元` }}</div>
                        </div>
                      </div>
                      <div class="coupon-item-right w-flex w-flex-justify-center w-flex-align-center cursor-pointer" @click="onToCoupon(cItem)">
                        {{ cItem.reachThresholdFlag ? "再逛逛" : "去凑单" }}
                      </div>
                    </div>
                    <div class="cart-coupon-swiper">
                      <swiper
                        :ref="'mySwiper' + cItem.couponCustomerNo"
                        :options="swiperOption"
                      >
                        <swiper-slide v-for="(pItem, pIndex) in cItem.productList" :key="pItem.productId + pIndex">
                          <div class="swiper-slide-cart w-flex w-flex-justify-center w-flex-align-center">
                            <img class="swiper-slide-img" :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="" mode="aspectFit">
                            <div v-if="pItem.selected" class="swiper-slide-select">
                              <img :src="require('@/assets/images/car/selected.png')" alt="">
                            </div>
                            <div class="swiper-slide-num w-flex w-flex-justify-center w-flex-align-center">
                              <span>x</span>{{ pItem.num }}
                            </div>
                          </div>
                        </swiper-slide>
                        <div v-if="cItem?.productList?.length> 6" slot="button-prev" class="banner-button-prev">
                          <Icon type="ios-arrow-down" :size="12" :color="'#fff'" />
                        </div>
                        <div v-if="cItem?.productList?.length> 6" slot="button-next" class="banner-button-next">
                          <Icon type="ios-arrow-up" :size="12" :color="'#fff'" />
                        </div>
                      </swiper>
                    </div>
                  </div>
                </div>
              </template>
            </Poptip>
          </div>
        </div>
        <div class="right-info">
          <div v-if="item.couponList&&item.couponList.length>0" class="get-coupon">
            <Poptip v-model="item.showCoupon1" placement="bottom-end">
              <div class="wrap">
                <p class="txt">领券</p>
                <img class="arrow" :src="item.showCoupon?require(`@/assets/images/car/arrow-up.png`):require(`@/assets/images/car/arrow-down.png`)" alt="">
              </div>
              <template #content>
                <div class="coupon-wrap">
                  <CouponItem v-for="(cItem,cIndex) in item.couponList" :key="cIndex" page="cart" :item-data.sync="item.couponList[cIndex]" />
                </div>
              </template>
            </Poptip>
          </div>
          <div class="tips-wrap">
            <span class="tips-text">{{ item.orgPostage.saleAmount?item.orgPostage.saleAmount:0 }}元起配，{{ item.orgPostage.noPostage?returnFloat(item.orgPostage.noPostage, '0.01'):0.00 }}元包邮</span>
            <span v-if="item.orgPostage.difference>0&&item.actualPrice>0" class="detail">
              <span class="poor-amount">{{ item.orgPostage.expandMap&&Object.keys(item.orgPostage.expandMap).includes('delivery')?'起配':'包邮' }}还差<span class="money">{{ returnFloat(item.orgPostage.difference, '0.01')||0.00 }}元</span></span>
              <span>+运费{{ returnFloat(item.orgPostage.postage || 0, '0.01') }}元</span>
            </span>
          </div>
          <span class="tips-text cursor" @click="jumpShopHome(item)">
            去凑单
            <Icon type="ios-arrow-forward" />
          </span>
          <span class="open-close cursor" @click="changeStatus(item,index)">{{ item.expandStatus?'收起':'展开' }}</span>
        </div>
        <div v-if="item.showCoupon" :id="`modal${storeCurrentIndex}`" class="coupon-modal" :class="index==validCartList.length-1&&productListLen<3&&validCartList.length>1?'last-coupon-modal':''">
          <CouponItem v-for="(cItem,cIndex) in item.couponList" :key="cIndex" page="cart" :item-data.sync="item.couponList[cIndex]" />
        </div>
      </div>
      <div v-if="item.expandStatus" class="goods">
        <div v-for="(sItem, sIndex) in item.activityList" v-if="item.activityList.length>0" :key="sIndex" class="item">
          <!-- 满减活动 11循环满减 12阶梯满减 -->
          <div v-if="sItem.activityType==ACTIVITY_TYPE.CYCLEMINUS||sItem.activityType==ACTIVITY_TYPE.LADDERMINUS||sItem.activityType==ACTIVITY_TYPE.SING_CYCLE_MINUS||sItem.activityType==ACTIVITY_TYPE.SING_LADDER_MINUS" class="rule-info">
            <span class="tag">满减</span>
            <span class="text" v-html="sItem.activityDesc" />
            <span class="collect-money" @click="jumpCollectPage(sItem,'fullDecrement',item)">
              去凑单
              <Icon type="ios-arrow-forward" />
            </span>
          </div>
          <!-- 满折活动 -->
          <div v-if="sItem.activityType==ACTIVITY_TYPE.DISCOUNT" class="rule-info">
            <span class="tag">满折</span>
            <span class="text" v-html="sItem.activityDesc" />
            <span class="collect-money" @click="jumpCollectPage(sItem,'fullFold',item)">
              去凑单
              <Icon type="ios-arrow-forward" />
            </span>
          </div>
          <!-- 满换活动 -->
          <div v-if="sItem.activityType==ACTIVITY_TYPE.AUTOEXCHANGE||sItem.activityType==ACTIVITY_TYPE.MANUALEXCHANGE" class="rule-info">
            <span class="tag">满换</span>
            <span class="text" v-html="sItem.activityDesc" />
            <span v-if="!(sItem.activityType==ACTIVITY_TYPE.AUTOEXCHANGE&&sItem.reachPrice==true)" class="collect-money" @click="jumpCollectPage(sItem,'fullChange',item)">
              {{ sItem.reachPrice?"去换购":"去凑单" }}
              <Icon type="ios-arrow-forward" />
            </span>
          </div>
          <!-- 满赠活动 -->
          <div v-if="sItem.activityType==ACTIVITY_TYPE.GIFT_AUTO||sItem.activityType==ACTIVITY_TYPE.GIFT_MANUAL" class="rule-info">
            <span class="tag">{{ sItem.stairStep ? '阶梯满赠' : '满赠' }}</span>
            <span class="text" v-html="sItem.activityDesc" />
            <span v-if="sItem.activityType==ACTIVITY_TYPE.GIFT_MANUAL" class="collect-money" @click="jumpCollectPage(sItem,'fullGift',item)">
              {{ sItem.reachPrice?"选赠品":"去凑单" }}
              <Icon type="ios-arrow-forward" />
            </span>
            <span v-if="sItem.activityType==ACTIVITY_TYPE.GIFT_AUTO" class="collect-money" @click="jumpCollectPage(sItem,'fullGift',item)">
              去凑单
              <Icon type="ios-arrow-forward" />
            </span>
          </div>
          <!-- 组合活动 -->
          <div v-else-if="isCombinationGood(sItem)" class="rule-info">
            <span class="tag">组合</span>
            <span class="text">{{ sItem.activityDesc }}</span>
            <span v-if="sItem.activityType == ACTIVITY_TYPE.CUSTOMCOMBINATION" class="collect-money" @click="openCombinationPopup(sItem)">
              去选购
              <Icon type="ios-arrow-forward" />
            </span>
          </div>
          <div v-if="isCombinationGood(sItem)" class="good-content-make">
            <Checkbox v-model="sItem.checked" :label="sItem.activityId" @on-change="activityRadioChange(sItem,index,sIndex)" />
            <div class="good-make-box">
              <div class="goods-make">
                <div v-for="(gItem, gIndex) in sItem.productList" v-if="sItem.productList.length>0&&gIndex<4" :id="`list_${gItem.id}`" :key="gIndex" class="goods-item">
                  <div class="goods-item-img">
                    <div class="goods-img-box" @click="toGoodDetail(gItem)">
                      <img :src="gItem.productLogo?getFilePath('PS_01',gItem.productLogo):defaultImg" alt="">
                      <!-- <img v-if="gItem.prescriptionType&&gItem.prescriptionType==0" class="prescription-img" src="@/assets/images/common/prescription.png" /> -->
                    </div>
                    <span class="num">x{{ gItem.num }}</span>
                  </div>
                  <div class="new-price">¥{{ returnFloat(goodPrice(sItem,gItem)) }}</div>
                  <div v-if="!gItem.couponPriceList" class="old-price">¥{{ returnFloat(gItem.price) }}</div>
                  <div v-else class="actual-price">
                    <span>¥{{ returnFloat(gItem.actualPrice, gItem.price) }}</span>
                    <div class="actual-price-label">到手价</div>
                  </div>
                </div>
                <div v-if="sItem.productList.length>4" class="goods-item more-img" @click="openCombinationPopup(sItem)">
                  <div class="goods-item-img">
                    <div class="img-box">
                      <img class="more-make" src="../../../assets/images/car/more.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="price-make">
                <span />
                <span />
                <span />
              </div>
              <div class="price-make">
                <span />
                <span />
                <span />
              </div>
              <div class="step-box-make">
                <div class="input-wrap">
                  <InputNumber
                    v-model="sItem.expandMap.units"
                    controls-outside
                    placeholder="请输入"
                    :max="9999999"
                    @click.native.stop="combinationKeydown($event,sItem,index,sIndex)"
                    @on-blur="combinationChangeNum(sItem,index,sIndex)"
                  />
                  <!-- <Loading :is-loading="sItem.numLoading" type="num" :deal="deal" :show-txt="false" /> -->
                </div>
                <span />
                <span />
              </div>
              <div class="amount-make">
                <div v-if="sItem.activityDiscountAmount" class="new-price">¥{{ returnFloat(sItem.activityDiscountAmount||0, '0.01') }}</div>
                <!-- <div class="old-price" v-if="sItem.activityAmount">¥{{ returnFloat(sItem.activityAmount||0) }}</div> -->
              </div>
              <div class="handle-make">
                <Poptip v-model="sItem.delModalShow">
                  删除
                  <template #content>
                    请确认是否删除此商品？
                    <span class="del-good" @click="deleteGroupGoods(sItem)">确定</span>
                  </template>
                </Poptip>
                <span />
              </div>
            </div>
          </div>
          <div v-else-if="isFullGiftGood(sItem)" class="good-content-gift">
            <div v-for="(gItem, gIndex) in sItem.productList" :id="`list_${gItem.id}`" :key="gIndex" :class="isGiftGood(sItem,gItem)?'container-gift':'good-content-container'">
              <div v-if="sItem.productList.length>0" class="good-content-detail">
                <Checkbox
                  v-if="!isGiftGood(sItem,gItem)"
                  v-model="gItem.checked"
                  :disabled="gItem.isLimit>0&&gItem.residualQty<=0"
                  :label="gItem.id"
                  @on-change="checkAllGroupChangeCampany(gItem,index,sIndex,gIndex)"
                />
                <div class="good-content-box" :class="isGiftGood(sItem, gItem)?'gift-content-box':''">
                  <div class="good-img-text" :class="isGiftGood(sItem, gItem)?'gift-good-text':''">
                    <div class="good-img-box" @click="navigateToGood(gItem,sItem)">
                      <div class="img-box">
                        <img :src="gItem.productLogo?getFilePath('PS_01',gItem.productLogo):getDefaultImg(sItem,gItem)" alt="">
                      </div>
                      <span v-if="isGiftGood(sItem,gItem)" class="exchange-tip">赠品</span>
                      <!-- 赠品数量 -->
                      <div v-if="isGiftGood(sItem,gItem)" class="gift-good-num">x{{gItem.num}}</div>
                      
                    </div>
                    <!-- 赠品名称 -->
                    <div v-if="isGiftGood(sItem,gItem)" class="gift-good-name">
                      <template>
                        <Tooltip v-if="gItem.productName" max-width="280px">
                          <template #content>
                            {{ gItem.productName }}
                          </template>
                          <span class="good-name" @click="navigateToGood(gItem,sItem)">{{ gItem.productName }}</span>
                        </Tooltip>
                      </template>
                    </div>
                    <div v-if="!isGiftGood(sItem,gItem)" class="text-info">
                      <template>
                        <Tooltip v-if="gItem.productName" max-width="280px">
                          <template #content>
                            {{ gItem.productName }}
                          </template>
                          <span class="good-name" @click="navigateToGood(gItem,sItem)">{{ gItem.productName }}</span>
                        </Tooltip>
                      </template>
                      <span v-if="!isGiftGood(sItem,gItem)" class="date">{{ gItem.manufacture||'' }}</span>
                      <span v-if="gItem.status!=='lose'&&!isGiftGood(sItem,gItem)" class="date w-flex w-flex-align-center">
                        <span :class="{active:isShowExpireDate(gItem)}">{{ isShowExpireDate(gItem) ? "近效期" : "有效期至" }}：</span>
                        <span :class="{active: gItem.showExpirationWarn }">{{ gItem.expireDate ? gItem.expireDate.substring(0,11): "-" }}</span>
                        <span v-if="isShowFarEffectDate(gItem) && sItem.activityType !==ACTIVITY_TYPE.NEARTERM" :class="{active: isShowExpireDate(gItem) }">/</span>
                        <span v-if="isShowFarEffectDate(gItem) && sItem.activityType !==ACTIVITY_TYPE.NEARTERM" :class="{active: gItem.showFarExpirationWarn }">{{ gItem.farEffectDate? gItem.farEffectDate.substring(0,11):'-' }}</span>
                      </span>
                      
                      <!-- 商品标签 -->
                      <div class="label-box">
                        <div v-if="gItem.couponMsg" class="tag-item">券</div>

                        <!-- 活动时间显示 开始 -->
                        <div v-if="gItem.status!=='lose'&&gItem.activityType==ACTIVITY_TYPE.SPECIAL&&gItem.expandMap&&gItem.expandMap.endTime" class="activity-time">
                          <CountTime :res-obj="{endTime:gItem.expandMap&&gItem.expandMap.endTime?new Date(gItem.expandMap.endTime).getTime():0}" @preIng="onRefresh" />
                        </div>
                        <div
                          v-for="(labelItem,labelIndex) in gItem?.showProductLabelNameList"
                          :key="labelIndex"
                          class="label-item"
                        >
                          {{ labelItem }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!isGiftGood(sItem,gItem)" class="good-content-right">
                    <div v-if="gItem.activityType != '71' && gItem.activityType != '72 && !isGiftGood(sItem,gItem)'" class="goods-pack">
                      <span>{{ gItem.packageNum ? gItem.packageNum : "-" }}/{{ gItem.packageUnitNum ? gItem.packageUnitNum : "-" }}</span>
                      <!-- <span>{{ gItem.allowRetail == 1 ? "可拆零" : "不可拆零" }}</span> -->
                    </div>
                    <div v-else class="goods-pack" />
                    <div v-if="!isGiftGood(sItem,gItem)" class="price">
                      <div class="activty-img">
                        <img v-if="gItem.status!=='lose'&&sItem.activityType==ACTIVITY_TYPE.SPECIAL" src="@/assets/images/trade/calculate.png" alt="">
                        <img v-if="gItem.status!=='lose'&&sItem.activityType==ACTIVITY_TYPE.NEARTERM" src="@/assets/images/car/near_expiry_date.png" alt="">
                        <img v-if="gItem.activityType == '8'" src="@/assets/images/car/fixed_price.png" alt="">
                      </div>
                      <span class="show-price">¥{{ returnFloat(goodPrice(sItem,gItem)) }}</span>
                      <span v-if="isShowOriginPrice(sItem,gItem)" class="lineation-price">¥{{ returnFloat(gItem.price) }}</span>
                      <span v-if="gItem.status!=='lose'&&isShowReceivedPrice(sItem, gItem)">
                        <span v-if="gItem.checked" class="estimate-price">
                          <span class="received-price-label">到手价</span>
                          ¥{{ returnFloat(gItem.actualPrice, gItem.price) }}</span>
                      </span>
                      <Poptip v-if="isShowPromotion(sItem,gItem)" v-model="gItem.promotionModalShow" @on-popper-show="promotionModalShowHandle(gItem,sItem,item,index,sIndex,gIndex)">
                        <span>
                          换促销
                          <Icon type="ios-arrow-down" />
                        </span>
                        <template #content>
                          <RadioGroup v-model="gItem.activityId" vertical @on-change="(value)=>promotionChange(value,gItem)">
                            <Radio v-for="(activityItem,activityIndex) in gItem.activityList" :key="activityItem.activityId" :label="activityItem.activityId">
                              <span>{{ activityItem.activityDesc }}</span>
                            </Radio>
                          </RadioGroup>
                        </template>
                      </Poptip>
                      <span v-if="!isOrActivityGoods(sItem, gItem)" class="seat" />
                      <span v-if="!isShowPromotion(sItem,gItem)" class="seat" />
                    </div>
                    <div v-if="gItem.status!=='lose'&&!isAutoExchangeGood(sItem,gItem)&&!isGiftGood(sItem,gItem)" class="stepper-box">
                      <div class="input-wrap">
                        <InputNumber
                          v-model="gItem.num"
                          :disabled="gItem.isLimit>0&&gItem.residualQty<=0"
                          :readonly="gItem.status!=='lose'&&isAutoExchangeGood(sItem,gItem)"
                          controls-outside
                          :max="9999999"
                          :step="gItem.addBuyNum"
                          placeholder="请输入"
                          @click.native.stop="keydown($event,gItem,index,sIndex,gIndex,sItem)"
                          @on-blur="changeNum(gItem,index,sIndex,gIndex,sItem)"
                        />
                      </div>
                      <span v-if="gItem.reason" class="num">{{ getReason(gItem,sItem) }}</span>
                      <span v-else />
                      <span />
                    </div>
                    <div v-if="gItem.status!=='lose'&&(isAutoExchangeGood(sItem,gItem)&&!isGiftGood(sItem,gItem))" class="stepper-box">
                      <span>{{ gItem.num }}</span>
                      <span />
                      <span />
                    </div>
                    <div v-if="!isGiftGood(sItem,gItem)" class="amount">
                      <span>¥{{ isShowActivityPrice(sItem,gItem)?returnFloat(gItem.activityAmount, '0.01'):returnFloat(gItem.amount, '0.01') }}</span>
                      <span />
                      <span />
                    </div>
                    <div v-if="!isGiftGood(sItem,gItem)&&!isAutoExchangeGood(sItem,gItem)" class="handle">
                      <Poptip v-model="gItem.delModalShow">
                        删除
                        <template #content>
                          请确认是否删除此商品？
                          <span class="del-good" @click="deleteSkuItemFn(gItem,gIndex,sIndex,index,sItem)">确定</span>
                        </template>
                      </Poptip>
                      <span />
                      <span />
                    </div>
                    <div v-else class="handle" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="good-content">
            <div v-for="(gItem, gIndex) in sItem.productList" :id="`list_${gItem.id}`" :key="gIndex" class="good-content-container" :class="isFullExchangeGood(sItem,gItem)?'replacement':''">
              <div v-if="sItem.productList.length>0" class="good-content-detail">
                <Checkbox
                  v-if="!isGiftGood(sItem,gItem)"
                  v-model="gItem.checked"
                  :disabled="gItem.isLimit>0&&gItem.residualQty<=0"
                  :label="gItem.id"
                  @on-change="checkAllGroupChangeCampany(gItem,index,sIndex,gIndex)"
                />
                <div class="good-content-box" :class="isGiftGood(sItem, gItem)?'gift-content-box':''">
                  <div class="good-img-text" :class="isFullExchangeGood(sItem,gItem)||isGiftGood(sItem, gItem)?'exchange-good-text':''">
                    <div class="good-img-box" @click="navigateToGood(gItem,sItem)">
                      <div class="img-box">
                        <img :src="gItem.productLogo?getFilePath('PS_01',gItem.productLogo):getDefaultImg(sItem,gItem)" alt="">
                        <!-- <img v-if="gItem.prescriptionType&&gItem.prescriptionType==0" class="prescription-img" src="@/assets/images/common/prescription.png" /> -->
                      </div>
                      <span v-if="isGiftGood(sItem,gItem)" class="exchange-tip">赠品</span>
                      <span v-if="isFullExchangeGood(sItem,gItem)" class="exchange-tip">换购品</span>
                    </div>
                    <div class="text-info">
                      <template>
                        <Tooltip v-if="gItem.productName" max-width="280px">
                          <template #content>
                            {{ gItem.productName }}
                          </template>
                          <span class="good-name" @click="navigateToGood(gItem,sItem)">{{ gItem.productName }}</span>
                        </Tooltip>
                      </template>
                      <span v-if="!isGiftGood(sItem,gItem)" class="date">{{ gItem.manufacture||'' }}</span>
                      <span v-if="gItem.status!=='lose'&&!isGiftGood(sItem,gItem)" class="date w-flex w-flex-align-center">
                        <span :class="{active:isShowExpireDate(gItem)}">{{ isShowExpireDate(gItem) ? "近效期" : "有效期至" }}：</span>
                        <span :class="{active: gItem.showExpirationWarn }">{{ gItem.expireDate ? gItem.expireDate.substring(0,11): "-" }}</span>
                        <span v-if="isShowFarEffectDate(gItem) && sItem.activityType !==ACTIVITY_TYPE.NEARTERM" :class="{active: isShowExpireDate(gItem) }">/</span>
                        <span v-if="isShowFarEffectDate(gItem) && sItem.activityType !==ACTIVITY_TYPE.NEARTERM" :class="{active: gItem.showFarExpirationWarn }">{{ gItem.farEffectDate? gItem.farEffectDate.substring(0,11):'-' }}</span>
                      </span>
                      
                      <!-- 商品标签 -->
                      <div class="label-box">
                        <div v-if="gItem.couponMsg" class="tag-item">券</div>

                        <!-- 活动时间显示 开始 -->
                        <div v-if="gItem.status!=='lose'&&gItem.activityType==ACTIVITY_TYPE.SPECIAL&&gItem.expandMap&&gItem.expandMap.endTime" class="activity-time">
                          <CountTime :res-obj="{endTime:gItem.expandMap&&gItem.expandMap.endTime?new Date(gItem.expandMap.endTime).getTime():0}" @preIng="onRefresh" />
                        </div>
                        <div
                          v-for="(labelItem,labelIndex) in gItem?.showProductLabelNameList"
                          :key="labelIndex"
                          class="label-item"
                        >
                          {{ labelItem }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="good-content-right">
                    <div v-if="gItem.activityType != '71' && gItem.activityType != '72 && !isGiftGood(sItem,gItem)'" class="goods-pack">
                      <span>{{ gItem.packageNum ? gItem.packageNum : "-" }}/{{ gItem.packageUnitNum ? gItem.packageUnitNum : "-" }}</span>
                      <!-- <span>{{ gItem.allowRetail == 1 ? "可拆零" : "不可拆零" }}</span> -->
                    </div>
                    <div v-else class="goods-pack" />
                    <div v-if="!isGiftGood(sItem,gItem)" class="price">
                      <div class="activty-img">
                        <img v-if="gItem.status!=='lose'&&sItem.activityType==ACTIVITY_TYPE.SPECIAL" src="@/assets/images/trade/calculate.png" alt="">
                        <img v-if="gItem.status!=='lose'&&sItem.activityType==ACTIVITY_TYPE.NEARTERM" src="@/assets/images/car/near_expiry_date.png" alt="">
                        <img v-if="gItem.activityType == '8'" src="@/assets/images/car/fixed_price.png" alt="">
                      </div>
                      <span class="show-price">¥{{ returnFloat(goodPrice(sItem,gItem)) }}</span>
                      <span v-if="isShowOriginPrice(sItem,gItem)" class="lineation-price">¥{{ returnFloat(gItem.price) }}</span>
                      <span v-if="gItem.status!=='lose'&&isShowReceivedPrice(sItem, gItem)">
                        <span v-if="gItem.checked" class="estimate-price">
                          <span class="received-price-label">到手价</span>
                          ¥{{ returnFloat(gItem.actualPrice, gItem.price) }}</span>
                      </span>
                      <Poptip v-if="isShowPromotion(sItem,gItem)" v-model="gItem.promotionModalShow" @on-popper-show="promotionModalShowHandle(gItem,sItem,item,index,sIndex,gIndex)">
                        <span>
                          换促销
                          <Icon type="ios-arrow-down" />
                        </span>
                        <template #content>
                          <RadioGroup v-model="gItem.activityId" vertical @on-change="(value)=>promotionChange(value,gItem)">
                            <Radio v-for="(activityItem,activityIndex) in gItem.activityList" :key="activityItem.activityId" :label="activityItem.activityId">
                              <span>{{ activityItem.activityDesc }}</span>
                            </Radio>
                          </RadioGroup>
                        </template>
                      </Poptip>
                      <span v-if="!isOrActivityGoods(sItem, gItem)" class="seat" />
                      <span v-if="!isShowPromotion(sItem,gItem)" class="seat" />
                    </div>
                    <div v-if="gItem.status!=='lose'&&!isAutoExchangeGood(sItem,gItem)&&!isGiftGood(sItem,gItem)" class="stepper-box">
                      <div class="input-wrap">
                        <InputNumber
                          v-model="gItem.num"
                          :disabled="gItem.isLimit>0&&gItem.residualQty<=0"
                          :readonly="gItem.status!=='lose'&&isAutoExchangeGood(sItem,gItem)"
                          controls-outside
                          :max="9999999"
                          :step="gItem.addBuyNum"
                          placeholder="请输入"
                          @click.native.stop="keydown($event,gItem,index,sIndex,gIndex,sItem)"
                          @on-blur="changeNum(gItem,index,sIndex,gIndex,sItem)"
                        />
                        <!-- <Loading :is-loading="gItem.numLoading" type="num" :deal="deal" :show-txt="false" /> -->
                      </div>
                      <span v-if="gItem.reason" class="num">{{ getReason(gItem,sItem) }}</span>
                      <span v-else />
                      <span />
                    </div>
                    <div v-if="gItem.status!=='lose'&&(isAutoExchangeGood(sItem,gItem)||isGiftGood(sItem,gItem))" class="stepper-box">
                      <span>{{ gItem.num }}</span>
                      <span />
                      <span />
                    </div>
                    <div v-if="!isGiftGood(sItem,gItem)" class="amount">
                      <span>¥{{ isShowActivityPrice(sItem,gItem)?returnFloat(gItem.activityAmount, '0.01'):returnFloat(gItem.amount, '0.01') }}</span>
                      <span />
                      <span />
                    </div>
                    <div v-if="!isGiftGood(sItem,gItem)&&!isAutoExchangeGood(sItem,gItem)" class="handle">
                      <Poptip v-model="gItem.delModalShow">
                        删除
                        <template #content>
                          请确认是否删除此商品？
                          <span class="del-good" @click="deleteSkuItemFn(gItem,gIndex,sIndex,index,sItem)">确定</span>
                        </template>
                      </Poptip>
                      <span />
                      <span />
                    </div>
                    <div v-else class="handle" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="subtotal">
        <div class="sub-num">已选商品<span>{{ item.selectedCount||0 }}</span>种，共计<span>{{ item.totalNum||0 }}</span>件</div>
        <div class="good-amount">商品金额<span>¥{{ returnFloat(item.totalPrice, '0.01') }}</span></div>
        <div v-if="item.discountAmount>0" class="good-amount">优惠<span>¥{{ returnFloat(item.discountAmount||'0', '0.01') }}</span></div>
        <div v-if="item.orgPostage.difference>0&&item.actualPrice>0" class="good-amount">运费<span>¥{{ returnFloat(item.orgPostage.postage||0, '0.01') }}</span></div>
        <div class="sub-amount">小计<span>¥{{ returnFloat(item.actualPrice, '0.01') }}</span></div>
      </div>
    </div>
    <div v-if="invalidCartList.length>0" class="lose-good">
      <div class="header">
        <span class="num">失效商品{{ invalidCartList.length }}件</span>
        <span class="clear" @click="clearLoseGoods">清空失效商品</span>
      </div>
      <div v-for="(item,index) in invalidCartList" :key="index" class="goods">
        <div class="good-content-box">
          <div class="good-img-text">
            <div class="good-img-box">
              <div class="img-box">
                <img :src="item.productLogo?getFilePath('PS_01',item.productLogo):defaultImg" alt="">
                <!-- <img v-if="item.prescriptionType&&item.prescriptionType==0" class="prescription-img" src="@/assets/images/common/prescription.png" /> -->
              </div>
              <!-- <span v-if="item.reason" class="lose-cause"><span>{{ getReason(item) }}</span></span> -->
            </div>
            <div class="text-info">
              <Tooltip v-if="item.productName" max-width="280px">
                <template #content>
                  {{ item.productName }}
                </template>
                {{ item.productName }}
              </Tooltip>
              <span class="date" />
              <span class="date">{{ item.expandMap.storeName }}</span>
              <span class="lose-state">{{ getReason(item) }}</span>
            </div>
          </div>
          <div class="handle">
            <Poptip v-model="item.delModalShow">
              删除
              <template #content>
                请确认是否删除此商品？
                <span class="del-good" @click="deleteSkuItemFn(item,index)">确定</span>
              </template>
            </Poptip>
            <span />
            <span />
          </div>
        </div>
      </div>
    </div>
    <Modal v-model="clearModal" title="提示" @on-ok="deleteLoseProductBatch" @on-cancel="cancel">
      确认将所有失效商品清空？
    </Modal>
    <!-- 组合活动弹窗 -->
    <ComposeModal :show-popup.sync="composeModal" :activity-info="activityInfo" />
    <!-- 加载效果 开始 -->
    <Loading :is-loading="isLoading" />
    <!-- 加载效果 结束 -->
  </div>
</template>

<script>
import { returnFloat, GOOD_CODE, ACTIVITY_TYPE, getFilePath, mul, div, getLimitInfo } from '@/common/utils'

import { deleteGoodsBatch, deleteLoseProductBatch, getMergeCouponInfo } from '@/common/service'
import { Message } from 'view-design'
import CountTime from './countTime/index.vue'
import ComposeModal from './composeModal.vue'
import Loading from '@/components/Loading.vue'
import CouponItem from '@/components/CouponItem'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import asyncQueryCartMixin from '@/common/mixins/asyncQueryCartMixin'
export default {
  components: {
    CountTime,
    ComposeModal,
    Loading,
    CouponItem,
    swiper,
    swiperSlide
  },
  mixins: [asyncQueryCartMixin],
  props: {
    validCartList: {
      type: Array,
      default: () => []
    },
    invalidCartList: {
      type: Array,
      default: () => []
    },
    customerObj: {
      type: Object,
      default: () => { }
    },
    pageType: {
      type: String,
      default: ''
    },
    checkedAllStatus: {
      type: Boolean,
      default: false
    },
    selectActivityId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      defaultImg: require('@/assets/images/common/good_default.png'),
      checkAll: false, // 全局全选
      clearModal: false,
      getFilePath,
      ACTIVITY_TYPE: ACTIVITY_TYPE,
      returnFloat,
      composeModal: false,
      activityInfo: {}, // 组合活动传给加购弹窗的值
      currentActivityId: '',
      deal: '',
      storeCurrentIndex: 0,
      cartCouponModal: false, // 购物车优惠券弹框
      mergeCouponList: [], // 优惠券列表
      activedTab: '0',
      tabs: [
        {
          name: '通用券',
          value: '0'
        },
        {
          name: '商品券',
          value: '1'
        }
      ],
      swiperOption: {
        // 左右箭头点击
        navigation: {
          nextEl: '.banner-button-next',
          prevEl: '.banner-button-prev'
        },
        slidesPerView: 6,
        spaceBetween: 8,
        // 分页器设置
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        notNextTick: true,
        // 循环
        loop: false,
        slidesPerGroup: 6,
        loopFillGroupWithBlank: true,
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 自动播放，等同下面autoplay设置
        autoplay: false,
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 800,
        // 轮播滑动方向
        direction: 'horizontal',
        // 小手掌抓取滑动
        // grabCursor : true,
        // 滑动之后回调函数
        on: {
          slideChangeTransitionEnd: function() {
            // console.log(this.activeIndex);//切换结束时，告诉我现在是第几个slide
          }
        }
      },
      mergeCouponsCurrency: [],
      mergeCouponsGoods: [],
      isLoading: false // 加载效果
    }
  },
  computed: {
    productListLen() {
      let sum = 0
      this.validCartList[this.validCartList.length - 1].activityList.reduce((prev, next) => {
        sum = prev + next.productList.length
      }, 0)
      return sum
    }
  },
  watch: {
    checkedAllStatus(newValue) {
      this.checkAll = newValue
    },
    selectActivityId(newValue) {
      this.currentActivityId = newValue
    }
  },
  mounted() {
    this.$bus.$on('position', (item) => {
      this.$nextTick(() => {
        document.getElementById(`list_${item.id}`).scrollIntoView({ block: 'start', behavior: 'smooth' })
      })
    })
    this.$nextTick(() => {
      document.addEventListener('click', (e) => {
        const ele = document.querySelector(`#modal${this.storeCurrentIndex}`)
        if (ele && !ele.contains(e.target)) {
          if (this.validCartList[this.storeCurrentIndex]?.showCoupon) {
            this.$set(this.validCartList[this.storeCurrentIndex], 'showCoupon', false)
            this.$forceUpdate()
          }
        }
      })
    })
  },
  methods: {
    getCouponList(item, index) {
      this.storeCurrentIndex = index
      item.showCoupon = !item.showCoupon
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          if (sItem.storeId == item.storeId) {
            this.$set(sItem, 'showCoupon', item.showCoupon)
          } else {
            this.$set(sItem, 'showCoupon', false)
          }
        }
      }
      this.$forceUpdate()
    },
    getReason(item, sItem) {
      if (item.reason) {
        if (item.reason == '102') {
          return `${item.startBuyNum}${item.productUnit}起购`
        } else if (item.reason == '103') {
          return `商家设置加购倍数为${item.addBuyNum}`
        } else if (item.reason == '104' || item.reason == '105') {
          return this.getTip(item, sItem)
        } else {
          return GOOD_CODE[item.reason]
        }
      } else {
        return ''
      }
    },
    // 商品详情
    navigateToGood(item, sItem) {
      if (item.status === 'lose') {
        return
      }
      // 跳转商品信息
      const flag = (item.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || item.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && item?.expandMap?.present == true // 是否换购品
      const giftFlag = (sItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || sItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && item?.expandMap?.present === true // 是否赠品
      const isNeedActivityType = sItem.activityType == ACTIVITY_TYPE.SPECIAL || sItem.activityType == ACTIVITY_TYPE.NEARTERM
      const activityType = isNeedActivityType || flag ? sItem.activityType : 0
      const activityId = isNeedActivityType || flag ? sItem.activityId : 0
      if (flag) {
        window.open(`/detail?productId=${item.productId}&activityType=${activityType}&activityId=${activityId}&isReplace=true`)
      } else if (giftFlag) {
        this.$router.push({ path: `/giftDetail?id=${item.productId}&activityType=${sItem.activityType}&activityId=${sItem.activityId}` })
      } else {
        window.open(`/detail?productId=${item.productId}&activityType=${activityType}&activityId=${activityId}`)
      }
    },
    toGoodDetail(item) {
      console.log(item)
      const { activityId, activityType, productId } = item
      window.open(`/detail?productId=${productId}&activityType=${activityType}&activityId=${activityId}`)
    },
    // 批量删除
    deleteGoodsBatch() {
      const productList = []; const presentProductList = []
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              if (gItem.checked) {
                productList.push({
                  id: gItem.id,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  productId: gItem.productId,
                  expandMap: { present: gItem.expandMap.present }
                })
              }
            }
          }
        }
      }
      if (this.invalidCartList?.length > 0) {
        for (const IItem of this.invalidCartList) {
          if (IItem.checked) {
            productList.push({
              id: IItem.id
            })
          }
        }
      }
      const normalList = productList.filter(item => !((item.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || item.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
      item.activityType == ACTIVITY_TYPE.GIFT_AUTO || item.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && item.expandMap?.present == true)) || []
      if (normalList.length > 0) {
        const pIds = normalList.map(item => { return { id: item.id } }) || []
        const selectedProductList = []
        if (this.validCartList.length > 0) {
          for (const sItem of this.validCartList) {
            for (const aItem of sItem?.activityList) {
              for (const gItem of aItem?.productList) {
                const flag = (aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
                aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present == true
                const ids = pIds.map(item => item.id) || []
                if (gItem.checked && !ids.includes(gItem.id)) {
                  if (!flag) {
                    selectedProductList.push({
                      id: gItem.id,
                      productId: gItem.productId,
                      activityId: aItem.activityId,
                      activityType: aItem.activityType
                    })
                  }
                }
                // 31自动换购 32手动换购
                if (flag) {
                  presentProductList.push({
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    selected: gItem.checked,
                    num: gItem.num,
                    actualPrice: gItem.actualPrice,
                    price: gItem.price
                  })
                }
              }
            }
          }
        }
        this.isLoading = true
        deleteGoodsBatch({
          addressCode: this.customerObj.receiveDistrictNo,
          customerTypeId: this.customerObj?.customerTypeId,
          productList: pIds,
          presentProductList,
          selectedProductList
        }).then((res) => {
          this.isLoading = false
          this.$emit('handleCarData', res.data)
          Message.success('删除成功')
          this.$store.dispatch('car/updateCartNum')
          setTimeout(() => {
            if (res.data.storeList && res.data.storeList.length > 0) {
              this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
            } else {
              this.setSelectedProductList(JSON.parse(JSON.stringify([])))
            }
          }, 500)
          this.$emit('setAsyncQueryCart', this.validCartList)
        }).catch(err => {
          this.isLoading = false
          Message.error(err?.data?.message)
        })
      }
    },
    // 全局全选操作
    handleCheckAll() {
      this.$emit('updateCheckedAll', this.checkAll)
    },
    // 点击公司全选
    campanyCheckAll(item, index) {
      // item.checkd = !item.checked
      this.$emit('updateSelectShop', item.checked, index)
    },
    // 公司下商品选择点击
    checkAllGroupChangeCampany(gItem, index, sIndex, gIndex) {
      // gItem.checked = !gIte.checked
      if ((index || index === 0) && (sIndex || sIndex === 0)) {
        this.$bus.$emit('updateSelectGood', gItem, index, sIndex, gIndex)
      } else {
        this.$bus.$emit('updateInvalidGood', gItem, gIndex)
      }
    },
    openCombinationPopup(sItem) { // 购物车打开组合活动弹窗
      this.composeModal = true
      this.activityInfo = sItem
      // this.$u.vuex('selectedProductList', JSON.parse(JSON.stringify(this.validCartList)))
      this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
    },
    combinationKeydown(e, aItem, sIndex, aIndex) {
      const addElement = e.target.className.includes('ivu-input-number-controls-outside-up') || e.target.className.includes('ivu-icon-ios-add')
      const minusElement = e.target.className.includes('ivu-input-number-controls-outside-down') || e.target.className.includes('ivu-icon-ios-remove')
      if (addElement || minusElement) {
        this.combinationChangeNum(aItem, sIndex, aIndex)
      }
      if (addElement) {
        this.deal = 'add'
      } else if (minusElement) {
        this.deal = 'plus'
      } else {
        this.deal = ''
      }
    },
    combinationChangeNum(aItem, sIndex, aIndex) { // 组合活动修改套数
      let activityNum = Number(aItem.expandMap.units)
      const maxUnits = Number(aItem.expandMap.maxUnits)
      const unitsArr = []
      aItem.productList.forEach(gItem => {
        if (gItem.isLimit && gItem.startBuyNum && (gItem.activityNum || gItem.num) && gItem.residualQty) {
          if (gItem.isLimit > 0 && mul(gItem.startBuyNum, activityNum) > gItem.residualQty) {
            const num = Math.floor(div(gItem.residualQty, gItem.startBuyNum))
            unitsArr.push(num)
          }
        }
      })
      if (unitsArr?.length > 0 || activityNum > maxUnits) {
        const minUnits = Math.min(...unitsArr, maxUnits)
        if (minUnits < 1) {
          Message.warning('限购已达上限')
        } else {
          Message.warning(`剩余采购${minUnits}套`)
          // Message.warning(`${aItem.activityDesc || ''}活动限购，剩余采购：${minUnits}套`)
        }
        activityNum = minUnits
        if (activityNum < 1) {
          activityNum = 1
          this.$set(aItem, 'checked', true)
          this.$emit('updateCombination', aItem, sIndex, aIndex, activityNum, 'changeNum')
          return
        }
      }
      if (activityNum < 1) {
        Message.warning(`1套起购`)
        activityNum = 1
      }
      this.$set(aItem, 'checked', true)
      this.$emit('updateCombination', aItem, sIndex, aIndex, activityNum, 'changeNum')
    },
    activityRadioChange(sItem, sIndex, aIndex) { // 点击组合活动勾选按钮
      // sItem.checked = !sItem.checked
      this.$emit('updateCombination', sItem, sIndex, aIndex)
    },
    onRefresh() {
      this.$bus.$emit('getCartListFn')
    },
    // 促销显示
    promotionModalShowHandle(gItem, sItem, item, index, sIndex, gIndex) {
      this.$bus.$emit('changePromotion', gItem, sItem, item, index, sIndex, gIndex)
    },
    // 促销改变
    promotionChange(value, gItem) {
      let item = {}
      for (const i in gItem.activityList) {
        if (value === gItem.activityList[i].activityId) {
          item = gItem.activityList[i]
        }
      }
      gItem.promotionModalShow = false
      this.$emit('selectPromotion', item)
    },
    // 删除商品
    deleteSkuItemFn(item, index, sIndex, shopIndex, sItem) {
      item.delModalShow = false
      // 手动换购品删除
      if (sItem?.activityType == ACTIVITY_TYPE.MANUALEXCHANGE && item.expandMap?.present == true) {
        this.$emit('updateDeleteProduct', index, sIndex, shopIndex)
        Message.success('删除成功')
      }
      const selectedProductList = []; const presentProductList = []
      if (this.validCartList.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              const flag = (aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
              aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present == true
              if (gItem.checked && item.id != gItem.id) {
                if (!flag) {
                  selectedProductList.push({
                    id: gItem.id,
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    actualPrice: gItem.actualPrice,
                    activityType: aItem.activityType
                  })
                }
              }
              // 31自动换购 32手动换购 71自动赠品 72手动赠品
              if (flag) {
                presentProductList.push({
                  productId: gItem.productId,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  orgId: gItem.orgId,
                  selected: gItem.checked,
                  num: gItem.num,
                  actualPrice: gItem.actualPrice,
                  price: gItem.price
                })
              }
            }
          }
        }
      }
      this.isLoading = true
      deleteGoodsBatch({
        addressCode: this.customerObj.receiveDistrictNo,
        customerTypeId: this.customerObj?.customerTypeId,
        productList: [{ id: item.id }],
        presentProductList,
        selectedProductList
      }).then((res) => {
        this.isLoading = false
        this.$emit('handleCarData', res.data)
        if (item.id) {
          Message.success('删除成功')
          this.$store.dispatch('car/updateCartNum')
        }
        setTimeout(() => {
          if (res.data.storeList && res.data.storeList.length > 0) {
            this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
          } else {
            this.setSelectedProductList(JSON.parse(JSON.stringify([])))
          }
        }, 500)
        this.$emit('setAsyncQueryCart', this.validCartList)
      }).catch((err) => {
        this.isLoading = false
        Message.error(err?.data?.message)
      })
    },
    // 清空失效商品
    clearLoseGoods() {
      this.clearModal = true
    },
    keydown(e, gItem, index, sIndex, gIndex, sItem) {
      if (gItem.isLimit > 0 && gItem.residualQty <= 0) {
        return
      }
      const addElement = e.target.className.includes('ivu-input-number-controls-outside-up') || e.target.className.includes('ivu-icon-ios-add')
      const minusElement = e.target.className.includes('ivu-input-number-controls-outside-down') || e.target.className.includes('ivu-icon-ios-remove')
      if (addElement || minusElement) {
        this.changeNum(gItem, index, sIndex, gIndex, sItem)
      }
      if (addElement) {
        this.deal = 'add'
      } else if (minusElement) {
        this.deal = 'plus'
      } else {
        this.deal = ''
      }
    },
    getTip(gItem, sItem) {
      return getLimitInfo(gItem)
    },
    // 商品数量改变
    changeNum(gItem, index, sIndex, gIndex, sItem) {
      let num = gItem.num
      const val = (num - Number(gItem.startBuyNum)) % Number(gItem.addBuyNum)
      if (num > gItem.availableNum) {
        Message.warning('库存不足，数量修改失败')
        num = Number(gItem.availableNum) - (Number(gItem.availableNum) - Number(gItem.startBuyNum)) % Number(gItem.addBuyNum)
      } else if (num > gItem.residualQty && gItem.isLimit > 0) {
        Message.warning(this.getTip(gItem, sItem))
        num = Number(gItem.residualQty) - (Number(gItem.residualQty) - Number(gItem.startBuyNum)) % Number(gItem.addBuyNum)
      } else if (num < gItem.startBuyNum) {
        Message.warning(`${gItem.startBuyNum}${gItem.productUnit}起购`)
        num = gItem.startBuyNum
      } else if (val !== 0) {
        Message.warning(`商家设置加购倍数为${gItem.addBuyNum}`)
        num = num - val
      }
      if (num > 9999999) {
        Message.warning(`购买件数已达到上限哦`)
        num = 9999999 - (9999999 - Number(gItem.startBuyNum)) % Number(gItem.addBuyNum)
      }
      this.$set(gItem, 'num', num)
      this.$set(gItem, 'checked', true)
      // 待校验
      this.$bus.$emit('updateSelectGood', gItem, index, sIndex, gIndex, 'changeNum')
    },
    // 点击展开收起
    changeStatus(item, index) {
      item.expandStatus = !item.expandStatus
      this.$emit('updateExpandStatus', item.expandStatus, index)
    },
    // 删除所有失效商品弹窗  取消
    cancel() {
      this.clearModal = false
    },
    // 删除所有失效商品弹窗   确认
    deleteLoseProductBatch() {
      const productList = this.invalidCartList.map(item => { return { id: item.id } })
      deleteLoseProductBatch(
        productList
      ).then(() => {
        this.clearModal = false
        Message.success('删除成功')
        this.$bus.$emit('editCartInfo')
      }).catch((err) => {
        this.clearModal = false
        Message.error(err?.data?.message)
      })
    },
    // 去商家首页
    jumpShopHome(item) {
      this.$router.push({
        path: `/shopHome?orgId=${item.storeId}`
      })
    },
    // 跳到去凑单页
    jumpCollectPage(sItem, type, item) {
      if (type == 'fullDecrement' || type == 'fullFold') { // 满减去凑单  活动专区
        if (sItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || sItem.activityType == ACTIVITY_TYPE.LADDERMINUS || sItem.activityType == ACTIVITY_TYPE.DISCOUNT ||
        sItem.activityType == ACTIVITY_TYPE.SING_CYCLE_MINUS || sItem.activityType == ACTIVITY_TYPE.SING_LADDER_MINUS) {
          this.$router.push({
            path: `/shellList?activityId=${sItem.activityId}&activityAllDesc=${sItem.activityAllDesc}&activityType=${sItem.activityType || '11'}`
          })
        }
      } else if (type == 'fullChange') { // 满换去凑单
        if ((sItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || sItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && !sItem.reachPrice) { // 自动换购（手动换购未满足金额）去凑单  去满换活动专区
          this.$router.push({
            path: `/shellList?activityId=${sItem.activityId}&activityAllDesc=${sItem.activityAllDesc}&activityType=${sItem.activityType || '31'}`
          })
        } else if (sItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE && sItem.reachPrice) { // 手动换购去换购  （手动换购满足金额） 去换购列表
          // 换购品列表
          this.$router.push({
            path: `/shellList?activityId=${sItem.activityId}&activityAllDesc=${sItem.activityAllDesc}&activityType=99&activityCode=32`
          })
        }
      } else if (type == 'fullGift') { // 满赠、阶梯满赠
        if (sItem.activityType == ACTIVITY_TYPE.GIFT_AUTO) {
          this.$router.push({
            path: `/shellList?activityId=${sItem.activityId}&activityAllDesc=${sItem.activityAllDesc}&activityType=${sItem.activityType}&step=1&isStairStep=${sItem.stairStep ? 1 : 0}`
          })
        } else if (sItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL && !sItem.reachPrice) { // 满赠去凑单  去满赠活动专区
          this.$router.push({
            path: `/shellList?activityId=${sItem.activityId}&activityAllDesc=${sItem.activityAllDesc}&activityType=${sItem.activityType}`
          })
        } else if (sItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL && sItem.reachPrice) { // 手动满赠 去赠品加购列表
          this.$router.push({
            path: `/selectGift?activityId=${sItem.activityId}&activityAllDesc=${sItem.activityAllDesc}&activityType=${sItem.activityType}&page=addGift&stagePurchaseLimit=${sItem.expandMap?.stagePurchaseLimit}`
          })
        }
      }
    },
    isCanChangePromotion(sItem) {
      // 满减活动 11循环满减 12阶满减 13满折活动 14循环单价减 15阶梯单价减
      return sItem?.activityType === ACTIVITY_TYPE.CYCLEMINUS || sItem?.activityType === ACTIVITY_TYPE.LADDERMINUS || sItem?.activityType === ACTIVITY_TYPE.DISCOUNT ||
      sItem?.activityType == ACTIVITY_TYPE.SING_CYCLE_MINUS || sItem?.activityType == ACTIVITY_TYPE.SING_LADDER_MINUS ||
      sItem?.activityType === ACTIVITY_TYPE.AUTOEXCHANGE || sItem?.activityType === ACTIVITY_TYPE.MANUALEXCHANGE ||
      sItem?.activityType === ACTIVITY_TYPE.GIFT_AUTO || sItem?.activityType === ACTIVITY_TYPE.GIFT_MANUAL
    },
    isShowPromotion(sItem, gItem) {
      return gItem.tatus !== 'lose' && gItem?.activityList?.length > 1 && this.isCanChangePromotion(sItem) && !(gItem.isLimit > 0 && gItem.residualQty <= 0)
    },
    isActivityGood(sItem) {
      // 满减活动 11循环减 12阶梯满减 13满折活动
      return sItem?.activityType === ACTIVITY_TYPE.CYCLEMINUS || sItem?.activityType === ACTIVITY_TYPE.LADDERMINUS || sItem?.activityType === ACTIVITY_TYPE.DISCOUNT ||
      sItem?.activityType == ACTIVITY_TYPE.SING_CYCLE_MINUS || sItem?.activityType == ACTIVITY_TYPE.SING_LADDER_MINUS
    },
    isFullExchangeGood(sItem, gItem) {
      // 是否是被换购商品
      return (sItem?.activityType === ACTIVITY_TYPE.AUTOEXCHANGE || sItem?.activityType === ACTIVITY_TYPE.MANUALEXCHANGE) && gItem?.expandMap?.present === true
    },
    isGiftGood(sItem, gItem) {
      // 是否是赠品
      return (sItem?.activityType == ACTIVITY_TYPE.GIFT_AUTO || sItem?.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem?.expandMap?.present === true
    },
    getDefaultImg(sItem, gItem) {
      return (sItem?.activityType == ACTIVITY_TYPE.GIFT_AUTO || sItem?.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem?.expandMap?.present === true ? require('@/assets/product/gift_default.png') : require('@/assets/images/common/good_default.png')
    },
    // 是否是自动被换购商品
    isAutoExchangeGood(sItem, gItem) {
      return sItem?.activityType === ACTIVITY_TYPE.AUTOEXCHANGE && gItem.expandMap.present === true
    },
    // 是否展示原价
    isShowOriginPrice(sItem, gItem) {
      const boolean = !gItem.couponPriceList && ([ACTIVITY_TYPE.SPECIAL, ACTIVITY_TYPE.NEARTERM, ACTIVITY_TYPE.FIXED_PRICE].includes(sItem?.activityType) || this.isFullExchangeGood(sItem, gItem))
      return boolean
    },
    // 是否展示活动价
    isShowActivityPrice(sItem, gItem) {
      // 判断活动类型，润划算、一口价、近效期
      return [ACTIVITY_TYPE.SPECIAL, ACTIVITY_TYPE.NEARTERM, ACTIVITY_TYPE.FIXED_PRICE, ACTIVITY_TYPE.AUTOEXCHANGE, ACTIVITY_TYPE.MANUALEXCHANGE].includes(sItem?.activityType) || ([ACTIVITY_TYPE.AUTOEXCHANGE, ACTIVITY_TYPE.MANUALEXCHANGE].includes(sItem?.activityType) && gItem.expandMap.present)
    },
    // 获取商品实际单价/活动价
    goodPrice(sItem, gItem) {
      return this.isShowActivityPrice(sItem, gItem) || this.isCombinationGood(sItem) ? gItem.activityPrice : gItem.price
    },
    // 是否是组合活动商品
    isCombinationGood(sItem) {
      return sItem.activityType === ACTIVITY_TYPE.FIXEDCOMBINATION || sItem.activityType === ACTIVITY_TYPE.CUSTOMCOMBINATION
    },
    // 是否满赠活动商品
    isFullGiftGood(sItem) {
      return sItem.activityType === ACTIVITY_TYPE.GIFT_AUTO || sItem.activityType === ACTIVITY_TYPE.GIFT_MANUAL
    },
    isOrActivityGoods(sItem, gItem) {
      return this.isCanChangePromotion(sItem) || this.isShowOriginPrice(sItem, gItem)
    },
    onToCoupon(item) {
      this.$router.push({
        path: '/coupon/goodslist',
        query: {
          couponId: item.couponId,
          couponLabel: item.couponLabel,
          orgId: item.orgId,
          activityType: 98,
          scene: 'cart'
        }
      })
    },
    // 是否展示到手价，有商品券 ｜ 满减活动 11,12,13,14,15
    isShowReceivedPrice(sItem, gItem) {
      return gItem.couponPriceList || (sItem.reachPrice && [ACTIVITY_TYPE.CYCLEMINUS, ACTIVITY_TYPE.LADDERMINUS, ACTIVITY_TYPE.DISCOUNT, ACTIVITY_TYPE.SING_CYCLE_MINUS, ACTIVITY_TYPE.SING_LADDER_MINUS].includes(sItem.activityType))
    },

    async onMore(item) {
      this.mergeCouponsCurrency = []
      this.mergeCouponsGoods = []
      const mergeCouponsCurrency = item.mergeCouponsCurrency && item.mergeCouponsCurrency.length > 0 ? JSON.parse(JSON.stringify(item.mergeCouponsCurrency)) : []
      const mergeCouponsGoods = item.mergeCouponsGoods && item.mergeCouponsGoods.length > 0 ? JSON.parse(JSON.stringify(item.mergeCouponsGoods)) : []
      let productList = []
      if (item.activityList && item.activityList.length > 0) {
        item.activityList.forEach((ele) => {
          productList = [...productList, ...ele.productList]
        })
      }
      const params = {
        orgId: item.storeId,
        productList: productList,
        couponIdList: item.mergeCoupons.map((ele) => ele.couponId)
      }
      const res = await getMergeCouponInfo([params])
      if (res.code === 200) {
        if (res.data && res.data.length > 0) {
          const couponList = res.data[0].couponList
          if (couponList && couponList.length > 0) {
            couponList.forEach((ele) => {
              const index = mergeCouponsCurrency.findIndex((item) => item.couponId === ele.couponId)
              if (index > -1) {
                mergeCouponsCurrency[index].productList = ele.productList
              }
              const idx = mergeCouponsGoods.findIndex((item) => item.couponId === ele.couponId)
              if (idx > -1) {
                mergeCouponsGoods[idx].productList = ele.productList
              }
            })
            this.mergeCouponsCurrency = [...mergeCouponsCurrency]
            this.mergeCouponsGoods = [...mergeCouponsGoods]
            if (mergeCouponsCurrency.length < 1) {
              this.activedTab = "1"
            }
          }
        }
      }
    },
    // 处理近/有效期判断情况
    isShowFarEffectDate(gItem) {
      if (!gItem.farEffectDate && gItem.expireDate) return false
      return true
    },
    isShowExpireDate(gItem) {
      return gItem.showExpirationWarn === true || gItem.showFarExpirationWarn === true
    },
    // 删除组合购商品
    deleteGroupGoods(item) {
      const selectedProductList = []; const presentProductList = []
      if (this.validCartList.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              const flag = (aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
              aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present == true
              if (gItem.checked && item.id != gItem.id) {
                if (!flag) {
                  selectedProductList.push({
                    id: gItem.id,
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    actualPrice: gItem.actualPrice,
                    activityType: aItem.activityType
                  })
                }
              }
              // 31自动换购 32手动换购 71自动赠品 72手动赠品
              if (flag) {
                presentProductList.push({
                  productId: gItem.productId,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  orgId: gItem.orgId,
                  selected: gItem.checked,
                  num: gItem.num,
                  actualPrice: gItem.actualPrice,
                  price: gItem.price
                })
              }
            }
          }
        }
      }
      let pids = []
      if (item.productList && item.productList.length > 0) {
        pids = item.productList.map((ele) => {
          return {
            id: ele.id
          }
        })
      }
      this.isLoading = true
      deleteGoodsBatch({
        addressCode: this.customerObj.receiveDistrictNo,
        customerTypeId: this.customerObj?.customerTypeId,
        productList: pids,
        presentProductList,
        selectedProductList
      }).then((res) => {
        this.isLoading = false
        this.$emit('handleCarData', res.data)
        if (item.id) {
          Message.success('删除成功')
          this.$store.dispatch('car/updateCartNum')
        }
        setTimeout(() => {
          if (res.data.storeList && res.data.storeList.length > 0) {
            this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
          } else {
            this.setSelectedProductList(JSON.parse(JSON.stringify([])))
          }
        }, 500)
        this.$emit('setAsyncQueryCart', this.validCartList)
      }).catch((err) => {
        this.isLoading = false
        Message.error(err?.data?.message)
      })
    },
  }
}
</script>

<style scoped lang="less">
.good-content-box{
  ::v-deep span.ivu-radio + * {
    width:414px;
    display:block;
    word-break: break-all !important;
    line-height: auto !important;
    white-space: wrap !important;
  }
  ::v-deep .ivu-radio-group-vertical .ivu-radio-wrapper{
    display:flex;
    height:auto !important;
    line-height:auto !important;
  }
  ::v-deep .ivu-radio-inner {
    margin-top:7px;
  }
  ::v-deep .ivu-poptip-body{
    max-height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      display:none;
    }
  }

  .good-content-right {
    width: 735px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

::v-deep .ivu-input-number-controls-outside{
  width:112px;
  height:24px;
  padding:0 24px;
  .ivu-input-number-controls-outside-btn{
    width:24px;
    height:24px;
    line-height: 22px;
  }
  .ivu-input-number-input-wrap{
    height:24px;
    line-height: 24px;
    .ivu-input-number-input{
      height:24px;
    }
  }
}
::v-deep .ivu-checkbox-wrapper {
  margin-right: 0;
  .ivu-checkbox {
    margin-right: 16px;
  }
  .ivu-checkbox-checked {
    .ivu-checkbox-inner {
      background-color: #f99d33;
    }
  }
}
.header-all {
  display: flex;
  font-size: 12px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 32px 0 16px;
  box-sizing: border-box;
  background-color: #fafafa;
  border-radius: 8px;
  height: 40px;
  ::v-deep .ivu-checkbox-wrapper {
    font-size: 12px;
    line-height: 40px;
  }
  .handle-all-other {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .good-info {
      width: 360px;
      text-align: center;
    }
    .header-all-content {
      width: calc(100% - 360px);
      display: flex;
      justify-content: space-between;
    }
  }
}
.campany {
  margin-top:16px;
  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
    background-color: #fafafa;
    padding: 0 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position:relative;
    .coupon-modal{
      position:absolute;
      top:42px;
      left:512px;
      width: 432px;
      max-height: 394px;
      overflow-y:scroll;
      padding:24px;
      border-radius: 8px;
      border: 0.5px solid var(--error-6, #F53F3F);
      background: #FFF;
      box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
      box-sizing:border-box;
      z-index:999;
      &::-webkit-scrollbar {
        display: none;
      }
      .coupon-item{
        margin-top:16px;
        &:first-child{
          margin-top:0;
        }
      }
    }
    .last-coupon-modal{
      top:auto;
      bottom:42px;
    }
    .ivu-checkbox-wrapper {
      font-size: 12px;
      line-height: 20px;
    }
    .left-info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      ::v-deep{
        .ivu-checkbox-wrapper{
          align-items: center;
        }
      }
      ::v-deep {
        .ivu-tooltip {
          display: flex;
          align-items: center;
        }
      }
      .store-name{
        width: 200px;
        cursor:pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: rgba(0,0,0,.6);
        margin-right:32px;
        &:hover{
          color: var(--brand-7, #CE7720);
        }
      }
      .header-coupon-box {
        display: -webkit-inline-box;
        max-width: 320px;
        overflow: hidden;
        .header-coupon-item {
          display: flex;
          padding: 1px 8px;
          align-items: center;
          color: var(--error-7, #CB272D);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          background-color: #FEECEC;
          margin-right: 4px;
          border-radius: 4px;
          &.display-none {
            display: none;
          }
        }
      }
      .header-coupon-more {
        margin-left: 8px;
        span {
          color: rgba(0, 0, 0, 0.9);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
        .ivu-icon {
          transform: rotate(90deg);
        }
      }
    }
    .right-info {
      display: flex;
      align-items: center;
      .get-coupon{
        width: 66px;
        height: 22px;
        padding:0 8px;
        box-sizing:border-box;
        background-image: url("~@/assets/images/car/coupon_bg.png");
        background-size:100% 100%;
        margin-right: 32px;
        .wrap{
          display: flex;
          align-items:center;
          height:100%;
          padding: 0 1px 0 5px;
          box-sizing:border-box;
          border-left:0.5px dashed #CB272D;
          border-right:0.5px dashed #CB272D;
        }
        .txt{
          margin-right:2px;
          color: var(--error-7, #CB272D);
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
        }
        .arrow{
          width:16px;
          height:16px;
        }
      }
      span {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 20px;
      }
      .tips-text {
        margin-right: 32px;
      }
      .open-close {
        color: rgba(0, 0, 0, 0.60);
        &:hover{
          color:#3853E2;
        }
      }
      .cursor {
        cursor: pointer;
      }
      .tips-wrap{
        display: flex;
        align-items: center;
        margin-right: 6px;
        .tips-text{
          margin-right:0;
        }
        .poor-amount{
          margin-left:8px;
          margin-right:8px;
          .money{
            color: var(--error-6, #F53F3F);
          }
        }
      }
    }
  }
  .goods {
    background-color: #fff;
    .item {
      padding: 0px 16px;
      box-sizing: border-box;
      border-bottom: 1px dashed #eeeeee;
      .rule-info {
        display: flex;
        align-items: flex-start;
        padding-left: 38px;
        padding-top: 12px;
        .tag {
          margin-top:4px;
          padding: 1px 4px 0;
          box-sizing: border-box;
          border: 0.5px solid var(--error-6, #F53F3F);
          color: #F53F3F;
          font-weight: 500;
          background:#FEF5F5;
          border-radius: 4px;
          font-size: 8.5px;
          line-height: 16px;
          margin-right: 8px;
        }
        .text {
          max-width: 1020px;
          font-size: 14px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.9);
          margin-right: 8px;
        }
        .collect-money {
          margin-top:2px;
          font-size: 14px;
          line-height: 22px;
          color: #d4380d;
          cursor: pointer;
        }
      }
      .good-content,
      .replacement {
        .good-content-detail {
          display: flex;
          align-items: center;
          padding: 21px 0 12px 0px;
          .good-content-box {
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding-right: 16px;
            align-items: flex-start;
            .good-img-text {
              width: 384px;
              display: flex;
              justify-content: flex-start;
              .good-img-box {
                width: 80px;
                height: 80px;
                box-sizing: border-box;
                border: 1px solid rgba(238, 238, 238, 1);
                border-radius: 4px;
                position: relative;
                .img-box {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }
                .exchange-tip {
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  padding: 0 4px;
                  box-sizing: border-box;
                  color: var(--purple-6, #722ED1);
                  text-align: center;
                  font-size: 8.5px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                  background: #F8F5FD;
                  border: 0.5px solid #722ED1;
                  border-radius: 4.57px;
                }
                .nearterm-tip{
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  padding: 1px 6px 0;
                  box-sizing: border-box;
                  font-weight: 500;
                  font-size: 8.5px;
                  line-height: 16px;
                  color: #F53F3F;
                  background: #FEF5F5;
                  border: 0.5px solid #F53F3F;
                  border-radius: 4px;
                }
                img {
                  width: 64px;
                  height: 64px;
                }
                .img-box{
                  position: relative;
                  cursor: pointer;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 64px;
                    height: 64px;
                  }
                }
              }
              .text-info {
                margin-left:16px;
                width: 280px;
                display: flex;
                flex-direction: column;
                ::v-deep {
                  .ivu-tooltip{

                  }
                  .ivu-tooltip-rel {
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.9);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                  }
                }
                .text-productName-wrap{
                    width: 100%;
                  }
                .date {
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.6);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  &.active {
                    color: rgba(230, 125, 0, 1);
                  }
                  .active{
                    color: rgba(230, 125, 0, 1);
                  }
                  .alert-circle {
                    width: 16px;
                    height: 16px;
                    margin-top: -2px;
                  }
                }
                .good-name{
                  font-size: 14px;
                  line-height: 22px;
                  cursor:pointer;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                  color: rgba(0, 0, 0, 0.9);
                  font-weight: 500;
                  &:hover{
                    color:#CE7720;
                  }
                }
              }
            }
            .exchange-good-text{
              width: 336px;
              .good-img-box{
                width:74px;
                height:74px;
                padding:8px;
                box-sizing: border-box;
                border-radius: 3.2px;
                border: 1px solid var(--gray-2, #EEE);
                background: var(--font-white-0, #FFF);
                cursor: pointer;
                img {
                  width: 56px;
                  height: 56px;
                }
                .img-box{
                  position: relative;
                  cursor: pointer;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 56px;
                    height: 56px;
                  }
                }
              }
            }
            .goods-pack {
              width: 100px;
              font-size: 12px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.9);
              text-align: center;
              display: flex;
              flex-direction: column;
            }
            .price {
              width: 200px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              .activty-img {
                width: 100%;
                text-align: right;
                img {
                  width: 38px;
                  height: 21px;
                }
              }
              .show-price {
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
              }
              .lineation-price {
                font-size: 12px;
                line-height: 20px;
                text-decoration-line: line-through;
                color: rgba(0, 0, 0, 0.4);
                margin-top: 4px;
              }
              .estimate-price {
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: #CB272D;
                margin-top: 4px;
                .received-price-label {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
              ::v-deep .ivu-poptip {
                .ivu-poptip-rel {
                  cursor: pointer;
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.6);
                  &:hover{
                    color:#3853E2;
                  }
                }
              }
              .seat{
                height:24px;
              }
            }
            .stepper-box {
              width: 112px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .input-wrap{
                position: relative;
              }
              ::v-deep .ivu-input-number {
                .ivu-input-number-input-wrap > .ivu-input-number-input {
                  padding:0;
                  font-size: 12px;
                  line-height: 20px;
                  text-align: center;
                }
              }
              span {
                display: inline-block;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
              }
              .num {
                color: var(--error-7, #CB272D);
                font-size: 12px;
                line-height: 20px;
                margin-top: 4px;
              }
            }
            .amount {
              width: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              span {
                display: inline-block;
                height: 24px;
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
              }
            }
            .handle {
              width: 80px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              ::v-deep .ivu-poptip {
                .ivu-poptip-rel {
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.9);
                  cursor: pointer;
                  &:hover {
                    color: #3853E2;
                  }
                }
                .ivu-poptip-popper
                  > .ivu-poptip-content
                  > .ivu-poptip-inner
                  > .ivu-poptip-body
                  > .ivu-poptip-body-content {
                  font-size: 14px;
                  line-height: 22px;
                  color: rgba(0, 0, 0, 0.9);
                  .del-good {
                    color: rgba(0, 0, 0, 0.60);
                    cursor: pointer;
                    &:hover{
                      color: #3853e2;
                    }
                  }
                }
              }
              span {
                display: inline-block;
                height: 24px;
                margin-top: 4px;
                &:first-child {
                  cursor: pointer;
                  :hover {
                    color: #d4380d;
                  }
                }
              }
            }
          }
          .gift-content-box{
            padding-left:64px;
            justify-content: flex-start;
            .good-img-text{
              justify-content: flex-start;
              align-items: flex-start;
              .text-info{
                margin-left:16px;
              }
            }
            .stepper-box{
              margin-left: 180px;
            }
          }
        }
        .about-end {
          // padding-bottom: 16px;
          // padding-left: 40px;
          .act_time{
            ::v-deep .time_num li{
              border:none;
              height:auto;
            }
          }
        }
      }
      .replacement {
        padding-left: 32px;
        padding-bottom: 14px;
        .good-content-detail {
          background-color: rgba(241, 245, 254, 1);
          padding-left: 16px;
          .good-content-box > .price {
            span {
              margin-top: 4px;
              &:first-child {
                margin-top: 0px;
              }
            }
          }
        }
      }
      .good-content-make {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 12px 0px;
        .good-make-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 12px 16px 12px 16px;
          box-sizing: border-box;
          background-color: #f1f5fe;
          border-radius: 4px;
          .goods-make {
            width: 366px;
            display: flex;
            .goods-item {
              display: flex;
              flex-direction: column;
              margin-right: 8px;
              .new-price {
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.9);
                margin-top: 4px;
                &.active {
                  color: #cb272d;
                }
              }
              .old-price {
                font-size: 12px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.4);
                text-decoration-line: line-through;
              }
              .actual-price {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: #cb272d;
                .actual-price-label {
                  color: #cb272d;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
              .goods-item-img {
                width: 82px;
                height: 82px;
                padding:8px;
                box-sizing: border-box;
                border: 1px solid var(--gray-2, #EEE);
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                img {
                  width: 64px;
                  height: 64px;
                }
                .goods-img-box{
                  position:relative;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 64px;
                    height: 64px;
                  }
                }
                .img-box{
                  width: 20px;
                  height: 20px;
                  display:flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  &:hover{
                    background:#E7E7E7;
                    border-radius: 4px;
                  }
                }
                .more-make {
                  width: 12px;
                  height: 12px;
                }
                .num {
                  position: absolute;
                  bottom: 1px;
                  right: 1px;
                  padding: 0px 4px;
                  background-color: rgba(0, 0, 0, 0.6);
                  border-radius: 4px;
                  font-size: 10px;
                  line-height: 18px;
                  color: #fff;
                }
              }
            }
            .more-img{
              .goods-item-img{
                border:none;
              }
            }
          }
          .price-make {
            width: 128px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            span {
              display: inline-block;
              height: 24px;
              font-size: 16px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.9);
              margin-top: 4px;
            }
          }
          .step-box-make {
            width: 112px;
            padding-top: 4px;
            .input-wrap{
              position: relative;
            }
            ::v-deep .ivu-input-number {
              .ivu-input-number-input-wrap > .ivu-input-number-input {
                font-size: 12px;
                line-height: 24px;
                text-align: center;
              }
            }
            span {
              display: inline-block;
              height: 24px;
              font-size: 16px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.9);
              margin-top: 4px;
            }
          }
          .amount-make {
            width: 60px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .new-price {
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.9);
            }
            .old-price {
              font-size: 12px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.6);
              text-decoration-line: line-through;
              margin-top: 4px;
            }
            span {
              display: inline-block;
              height: 20px;
              font-size: 12px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          .handle-make {
            width: 80px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            ::v-deep .ivu-poptip {
              .ivu-poptip-rel {
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
                cursor: pointer;
                &:hover {
                  color: #d4380d;
                }
              }
              .ivu-poptip-popper
                > .ivu-poptip-content
                > .ivu-poptip-inner
                > .ivu-poptip-body
                > .ivu-poptip-body-content {
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.9);
                .del-good {
                  color: #3853e2;
                  cursor: pointer;
                }
              }
            }
            span {
              display: inline-block;
              height: 24px;
              font-size: 16px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.9);
              margin-top: 4px;
            }
          }
        }
      }
      .good-content-gift {
        display: flex;
        flex-wrap: wrap;
        background-color: rgba(248, 250, 255, 1);
        .good-content-container {
          width: 100%;
          background-color: #ffffff;
        }
        
        .good-content-detail {
          display: flex;
          align-items: center;
          padding: 21px 0 12px 0px;
          .good-content-box {
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding-right: 16px;
            align-items: flex-start;
            .good-img-text {
              width: 384px;
              display: flex;
              justify-content: flex-start;
              .good-img-box {
                width: 80px;
                height: 80px;
                box-sizing: border-box;
                border: 1px solid rgba(238, 238, 238, 1);
                border-radius: 4px;
                position: relative;
                background-color: #fff;
                .img-box {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }
                .exchange-tip {
                  position: absolute;
                  top: 2px;
                  left: 2px;
                  padding: 0 4px;
                  box-sizing: border-box;
                  color: var(--purple-6, #722ED1);
                  text-align: center;
                  font-size: 8.5px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                  background: #F8F5FD;
                  border: 0.5px solid #722ED1;
                  border-radius: 4px;
                }
                .nearterm-tip{
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  padding: 1px 6px 0;
                  box-sizing: border-box;
                  font-weight: 500;
                  font-size: 8.5px;
                  line-height: 16px;
                  color: #F53F3F;
                  background: #FEF5F5;
                  border: 0.5px solid #F53F3F;
                  border-radius: 4px;
                }
                img {
                  width: 64px;
                  height: 64px;
                }
                .img-box{
                  cursor: pointer;
                  position: relative;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 64px;
                    height: 64px;
                  }
                }
              }
              .text-info {
                margin-left:16px;
                width: 280px;
                display: flex;
                flex-direction: column;
                ::v-deep {
                  .ivu-tooltip{

                  }
                  .ivu-tooltip-rel {
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.9);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                  }
                }
                .text-productName-wrap{
                    width: 100%;
                  }
                .date {
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.6);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  &.active {
                    color: rgba(230, 125, 0, 1);
                  }
                  .active{
                    color: rgba(230, 125, 0, 1);
                  }
                  .alert-circle {
                    width: 16px;
                    height: 16px;
                    margin-top: -2px;
                  }
                }
                .good-name{
                  font-size: 14px;
                  line-height: 22px;
                  cursor:pointer;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                  color: rgba(0, 0, 0, 0.9);
                  font-weight: 500;
                  &:hover{
                    color:#CE7720;
                  }
                }
              }
            }
            .exchange-good-text{
              width: 336px;
              .good-img-box{
                width:74px;
                height:74px;
                padding:8px;
                box-sizing: border-box;
                border-radius: 3.2px;
                border: 1px solid var(--gray-2, #EEE);
                background: var(--font-white-0, #FFF);
                cursor: pointer;
                img {
                  width: 56px;
                  height: 56px;
                }
                .img-box{
                  cursor: pointer;
                  position: relative;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 56px;
                    height: 56px;
                  }
                }
              }
            }
            .goods-pack {
              width: 100px;
              font-size: 12px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.9);
              text-align: center;
              display: flex;
              flex-direction: column;
            }
            .price {
              width: 200px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              .activty-img {
                width: 100%;
                text-align: right;
                img {
                  width: 38px;
                  height: 21px;
                }
              }
              .show-price {
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
              }
              .lineation-price {
                font-size: 12px;
                line-height: 20px;
                text-decoration-line: line-through;
                color: rgba(0, 0, 0, 0.4);
                margin-top: 4px;
              }
              .estimate-price {
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: #CB272D;
                margin-top: 4px;
                .received-price-label {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
              ::v-deep .ivu-poptip {
                .ivu-poptip-rel {
                  cursor: pointer;
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.6);
                  &:hover{
                    color:#3853E2;
                  }
                }
              }
              .seat{
                height:24px;
              }
            }
            .stepper-box {
              width: 112px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .input-wrap{
                position: relative;
              }
              ::v-deep .ivu-input-number {
                .ivu-input-number-input-wrap > .ivu-input-number-input {
                  padding:0;
                  font-size: 12px;
                  line-height: 20px;
                  text-align: center;
                }
              }
              span {
                display: inline-block;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
              }
              .num {
                color: var(--error-7, #CB272D);
                font-size: 12px;
                line-height: 20px;
                margin-top: 4px;
              }
            }
            .amount {
              width: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              span {
                display: inline-block;
                height: 24px;
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
              }
            }
            .handle {
              width: 80px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              ::v-deep .ivu-poptip {
                .ivu-poptip-rel {
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.9);
                  cursor: pointer;
                  &:hover {
                    color: #3853E2;
                  }
                }
                .ivu-poptip-popper
                  > .ivu-poptip-content
                  > .ivu-poptip-inner
                  > .ivu-poptip-body
                  > .ivu-poptip-body-content {
                  font-size: 14px;
                  line-height: 22px;
                  color: rgba(0, 0, 0, 0.9);
                  .del-good {
                    color: rgba(0, 0, 0, 0.60);
                    cursor: pointer;
                    &:hover{
                      color: #3853e2;
                    }
                  }
                }
              }
              span {
                display: inline-block;
                height: 24px;
                margin-top: 4px;
                &:first-child {
                  cursor: pointer;
                  :hover {
                    color: #d4380d;
                  }
                }
              }
            }
          }
          .gift-content-box{
            padding-left: 8px;
            padding-right: 0;
            justify-content: flex-start;
            
            .good-img-text{
              justify-content: flex-start;
              align-items: flex-start;
              .text-info{
                margin-left:16px;
              }
            }
            .stepper-box{
              margin-left: 180px;
            }
            .gift-good-text {
              width: 80px;
              flex-wrap: wrap;
            }
          }
        }
        .container-gift {
          margin-left: 0;
          &:first-child {
            margin-left: 40px;
          }
          .good-content-detail {
            padding: 12px 0;
          }
          .gift-good-num {
            width: 18px;
            height: 18px;
            padding: 0px 4px;
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            font-size: 10px;
            font-weight: 400;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            position: absolute;
            right: 0;
            bottom: 0;
          }

          .gift-good-name {
            height: 22px;
            margin-top: 4px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .subtotal {
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 12px 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    .sub-num {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
      margin-right: 24px;
      span {
        color: #ce7720;
        margin: 0 4px;
      }
    }
    .good-amount {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
      margin-right: 24px;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 8px;
      }
    }
    .sub-amount {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.9);
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #cb272d;
        margin-left: 8px;
      }
    }
  }
}
.lose-good {
  margin-top:16px;
  width: 100%;
  .header {
    height:auto !important;
    padding: 8px 28px 8px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fafafa;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 14px;
    .num {
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.9);
    }
    .clear {
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.60);
      cursor: pointer;
      &:hover{
        color:#3853E2;
      }
    }
  }
  .goods {
    padding: 12px 32px 12px 50px;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px dashed #eeeeee;
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .good-content-box {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .good-img-text {
        width: 396px;
        display: flex;
        justify-content: space-between;
        .good-img-box {
          width: 82px;
          height: 82px;
          padding:8px;
          box-sizing: border-box;
          border: 1px solid var(--gray-2, #EEE);
          border-radius: 4px;
          position: relative;
          cursor: pointer;
          .lose-cause {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 62px;
            height: 62px;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.4);
            color: #fff;
            font-size: 14px;
            border-radius: 50%;
            padding: 0 10px;
          }
          img {
            width: 64px;
            height: 64px;
          }
          .img-box{
            position: relative;
            cursor: pointer;
            opacity: 0.5;
            .prescription-img{
              position: absolute;
              left:0;
              top:0;
              width: 64px;
              height: 64px;
            }
          }
        }
        .text-info {
          width: 280px;
          display: flex;
          flex-direction: column;
          ::v-deep .ivu-tooltip {
            width: 100%;
            height: 22px;
            .ivu-tooltip-rel {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.4);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: block;
            }
          }
          .date {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.4);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .lose-state {
            font-size: 12px;
            line-height: 20px;
            color: #CB272D;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .handle {
        width: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-top: 10px;
        ::v-deep .ivu-poptip {
          .ivu-poptip-rel {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.9);
            cursor: pointer;
            &:hover {
              color: #d4380d;
            }
          }
          .ivu-poptip-popper
            > .ivu-poptip-content
            > .ivu-poptip-inner
            > .ivu-poptip-body
            > .ivu-poptip-body-content {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            .del-good {
              cursor: pointer;
              color: rgba(0, 0, 0, 0.60);
              &:hover{
                color:#3853E2;
              }
            }
          }
        }
        span {
          display: inline-block;
          height: 24px;
          margin-top: 4px;
          &:first-child {
            cursor: pointer;
            :hover {
              color: #d4380d;
            }
          }
        }
      }
    }
  }
}
.good-tag {
  border: 1px solid red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.tag-item {
  display: inline-block;
  font-size: 12px;
  color: #F53F3F;
  border-radius: 4px;
  padding: 0 3.5px;
  border: 0.5px solid #F53F3F;
  margin-right: 4px;
  line-height: 18px;
  height: 18px;
  box-sizing: border-box;
}
::v-deep .ivu-poptip {
  .ivu-poptip-body {
    width: 406px;
    padding: 16px;
    max-height: 392px;
    overflow-y: scroll;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.cart-coupon-title {
  height: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.4);
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
.cart-coupon-item {
  width: 374px;
  margin: 0 auto;
  padding: 12px 0;
  .coupon-item-left {
    .coupon-item-type {
      width: 38px;
      height: 17px;
      padding: 2px 4px;
      border-radius: 4px;
      background-color: #FFF4F1;
      margin-right: 4px;
      margin-top: 2px;
      span {
        font-size: 10px !important;
        font-weight: 400 !important;
        color: #F53F3F !important;
      }
    }
    .coupon-item-content{
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.9);
      .coupon-item-money{
        position: relative;
        margin-left: 0;
        padding-left: 0;
        &.red {
          color: rgba(245, 63, 63, 1);
        }
      }
      &.active {
        display: flex;
        .coupon-item-money {
          margin-left: 4px;
          padding-left: 5px;
          &::before {
            content: "";
            width: 1px;
            height: 12px;
            background-color: rgba(238, 238, 238, 1);
            position: absolute;
            top: 5px;
            left: 0;
          }
          &.red {
            color: rgba(245, 63, 63, 1);
            &::before {
              content: "";
              width: 1px;
              height: 12px;
              background-color: rgba(253, 205, 197, 1);
              position: absolute;
              top: 5px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .coupon-item-right {
    display: flex;
    width: 52px;
    height: 22px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 400;
    background-color: #F53F3F;
    color: #ffffff;
  }
  .cart-coupon-swiper {
    width: 100%;
    height: 56px;
    margin-top: 12px;
  }
}
.label-box {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  &::after {
    content: "";
    display: block;
    width: 17px;
    height: 18px;
    position: absolute;
    top: 2px;
    right: 0;
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
  .activity-time {
    display: inline-block;;
  }
  .label-item {
    display: inline-block;
    //vertical-align: middle;
    height: 18px;
    padding: 0 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid rgba(160, 112, 60, 0.80);
    margin-right: 4px;

    color: #A0703C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.exchange-good-text {
  .label-box {
    &::after {
      content: "";
      display: none;
    }
  }
}
:deep(.get-coupon){
  cursor: pointer;
  .coupon-item{
    margin-top: 10px;
    margin-right: 0;
    &:first-child{
      margin-top: 0;
    }
  }
  .ivu-poptip-body{
    max-height: 502px;
    overflow-y: auto;
  }
  .ivu-poptip-body-content{
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
    }

    /* 为滚动条滑块设置样式 */
    &::-webkit-scrollbar-thumb {
      background-color:#E7E7E7;
      border-radius: 8px;
    }
  }
  .coupon-wrap{
    max-height: 470px;
    overflow-y: auto;
    width: 100%;

    /* 或者设置滚动条的样式 */
    &::-webkit-scrollbar {
      display: none;
    }

  }
}

.swiper-slide-cart {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 56px;
  height: 56px;
  position: relative;
  .swiper-slide-img {
    width: 45px;
    height: 45px;
  }
  .swiper-slide-select {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .swiper-slide-num {
    min-width: 24px;
    height: 18px;
    padding: 0px 4px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    position: absolute;
    bottom: 2px;
    left: 2px;
    background-color: rgba(0, 0, 0, 0.6);
    span {
      color: #ffffff !important;
    }
  }
}

.banner-button-prev, .banner-button-next {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  transform: translateY(-50%);
  &.swiper-button-disabled {
    display: none;
  }
}
.banner-button-prev {
  left: 0px;
  right: auto;
  padding-right: 2px;
}
.banner-button-next {
  right: 0px;
  left: auto;
  padding-left: 2px;
}

</style>
